import { createSlice } from '@reduxjs/toolkit';
import { fetchStages, updateEtapas } from './stagesThunks';
import { IS_FAILED, IS_LOADING, IS_SUCCEEDED } from '../../constants/redux/states';

const stagesSlice = createSlice({
  name: 'stages',
  initialState: {
    data: {},
    status: 'loading',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStages.pending, (state) => {
        state.status = IS_LOADING;
      })
      .addCase(fetchStages.fulfilled, (state, action) => {
        state.status = IS_SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchStages.rejected, (state, action) => {
        state.status = IS_FAILED;
        state.error = action.error.message;
      })
      .addCase(updateEtapas.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item.creditoNumero === action.payload.creditoNumero);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      });
  },
});

export default stagesSlice.reducer;