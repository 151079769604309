import { API } from 'aws-amplify';

const get = ({ creditoNumero, usuarioId }) =>
	API.get('estrella', '/sign', {
		queryStringParameters: {
			...(creditoNumero && { creditoNumero }),
			...(usuarioId && { usuarioId }),
		},
	});

const SignatureService = {
	get,
};

export default SignatureService;
