import Accordion from '../components/shared/Accordion';
import React, { useState, useEffect } from 'react';
import FinancialStatementService from '../services/process/financial-statement-service';
import Default from '../assets/images/financial-statement/default.png';
import FinancialData from '../components/FinancialSummary/FinancialData';
import { useDialog } from '../context/dialog-context';
import { useAuth } from '../context/auth-context';
/*
import DIALOG from '../constants/dialog';
*/
import Button from '../components/DynamicForm/Button';
import { MdClose } from 'react-icons/md';
import PaymentModal from '../components/dialog/PaymentModal';
import { PAYMENT_TYPE_CASH, PAYMENT_TYPE_PSEE } from '../constants/dialog/components/paymentDialog.constants';

const FinancialSummary = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [hideExternalPaymentButton, setHideExternalPaymentButton] = useState(
    []
  );
  const [textAdvertisement, setTextAdvertisement] = useState([]);
  const [hiddenTextAdvertisement, setHiddenTextAdvertisement] = useState([]);
  const { user } = useAuth();
  const dialog = useDialog();

  const fetchCustomerData = async () => {
    FinancialStatementService.postOpenCoop(user['custom:document'])
      .then((res) => setCustomerData(res.listCustomer))
      .then((res) => {
        setCustomerData(res.listCustomer);
        setHideExternalPaymentButton(res.hideExternalPaymentButton);
        setTextAdvertisement(res.textAdvertisement);
        setHiddenTextAdvertisement(res.hiddenTextAdvertisement);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const showPayment = () =>
    dialog.custom(
      <>
        <div className="flex justify-end">
          <button onClick={() => dialog.close()}>
            <MdClose className="h-auto w-6" />
          </button>
        </div>
        <PaymentModal />
      </>,
      { className: 'p-2', spacing: false }
    );

  return (
    <>
      {hiddenTextAdvertisement === false && (
        <p className="pb-6 text-center text-xl font-bold text-custom-green">
          {textAdvertisement}
        </p>
      )}
      <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
        Mi financiación
      </h2>
      <h3 className="pb-6 text-center font-bold">
        Resumen general de financiaciones
      </h3>
      <p className="pb-4">
        Número de financiaciones con Estrella:{' '}
        <span className="font-bold">{customerData?.length ?? 0}</span>
      </p>
      {customerData?.length ? (
        <div className="space-y-2">
          {customerData.map((req, index) => (
            <Accordion
              title={
                <>
                  Referencia de pago: {req.paymentReference} / Fecha:{' '}
                  {req.startDate} -{' '}
                  {req.progressState === 'En Progreso' ? (
                    <span className="text-custom-red">{req.progressState}</span>
                  ) : (
                    <span className="font-bold">{req.progressState}</span>
                  )}
                </>
              }
              index={index + 1}
              key={req.id ? req.id : req.paymentReference}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            >
              <div className="py-6">
                <FinancialData req={req} index={index + 1} />
              </div>
            </Accordion>
          ))}
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          <img src={Default} alt="background" />
        </div>
      )}
      {hideExternalPaymentButton === false && (
        <div className="my-4 grid grid-cols-1 items-center gap-6">
          <div className="flex flex-col space-y-1 text-center">
            <p className="font-bold">Para pagar por:</p>
            <p className="text-xs">
              {PAYMENT_TYPE_PSEE},
              <br /> {PAYMENT_TYPE_CASH}
            </p>
            <span>
              <Button
                onClick={showPayment}
                customColor="bg-custom-purple"
                className="px-10 text-lg"
              >
                Realizar
                <br /> Pago
              </Button>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default FinancialSummary;
