import React, { useState, useEffect, useMemo } from 'react';
import FormProgressBar from '../../../components/FinancingRequest/FormProgressBar';
import InfoEstudiante from './InfoEstudiante';
import ActEcoEstudiante from './ActEcoEstudiante';
import ProgramaFinanciar from './ProgramaFinanciar';
import InfoActEcoCodeudor from './InfoActEcoCodeudor';
import Autorizacion from './Autorizacion';
import ConstantesSolicitudService from '../../../services/shared/constantes-solicitud-service';
import { DIALOG } from '../../../constants/dialog/dialog';
import { useDialog } from '../../../context/dialog-context';
import Loading from '../../../components/shared/Loading';
import { Cache } from 'aws-amplify';
import Button from '../../../components/DynamicForm/Button';
import { useForm, FormProvider } from 'react-hook-form';
import { useAuth } from '../../../context/auth-context';
import transformFormData from '../../../utils/transform-form-data';
import transformFormDataRenovation from '../../../utils/transform-form-data-renovation';
import CreditoService from '../../../services/credit/credito-service';
import useUpload from '../../../hooks/use-upload';
import { FILES } from '../../../constants/files/files';
import { FILE_STATE } from '../../../constants/files/fileState';
import Dialog from '../../../components/dialog/Dialog';
import FormInput from '../../../components/Input/FormInput';
import useInput from '../../../hooks/use-input';
import { REQUEST_FORM } from '../../../constants/form/validations';
import {
  InstallmentProvider,
  useInstallment,
} from '../../../context/installment-context';
// import ZapsignService from '../../../services/process/zapsign-service';
import ConsultGeneral from '../../../services/consult/consult-general';
import HubspotCrmService from '../../../services/autorization/hubspotCrm-service';
import Simulador from '../../../components/FinancingRequest/Simulator';
import ProcessFinancingOrden from '../../../components/FinancingRequest/ProcessFinancingOrden';
import ExternadoService from '../../../services/stage/externado-service';
import moment from 'moment/moment';
import ArchivoService from '../../../services/file/archivo-service';
import EtapaService from '../../../services/stage/etapa-service';
import { ETAPAS } from '../../../constants/stage/etapa.js';
import { ESTADOS_ETAPA } from '../../../constants/stage/estadoEtapa.js';
import { useNavigate } from 'react-router-dom';
// import { useDarkMode } from '../../../context/dark-mode-context';
import OpenCoopCrmService from '../../../services/verification/open-coop-service';
import background1 from '../../../assets/images/renovation.svg';
import { RETURN } from '../../../constants/actions/buttons.constants.js';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET
  }
});


const RequestFormComp = ({fetchedConsultDataOpen = false, acceptedRenovation = false, isRenovationWhitCondition = false, fetchedConsultCreditRenovation = false}) => {
  const [fetchedLastCredit, setFetchedLastCredit] = useState(false);
  const dialog = useDialog();
  const [loading, setLoading] = useState(true);
  const [formStep, setFormStep] = useState(0);
  const [numCodeudoresObl, setNumCodeudoresObl] = useState(0);
  const [showAuthorization, setShowAuthorization] = useState(false);
  const [showLinea, setShowLinea] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [etapas, setEtapas] = useState();
  // const [showCodeudor1, setShowCodeudor1] = useState(false);
  const [formConstants, setFormConstants] = useState(
    Cache.getItem('form-constants')
  );
  console.log('formConstants', formConstants);
  const { installments } = useInstallment();
  const [infoOrdenMatricula, setInfoOrdenMatricula] = useState();
  // By changing the value of numCodeudores the form will create the additional steps for each co-signer
  const numCodeudores = 2;
  const numRef = 3;
  const navigate = useNavigate();

  const { user } = useAuth();
  const uploadProps = useUpload({
    fileName: `${user['custom:document']}_${FILES.ORDEN_MATRICULA.fileName}`,
  });
  const handleSeleccionChange = (nuevaSeleccion) => {
    if (nuevaSeleccion) {
      setValue('linea', nuevaSeleccion);
      setShowLinea(nuevaSeleccion);
    } else {
      setShowLinea(undefined);
    }
  };
  const handleSeleccionChangeApi = (nuevaSeleccion) => {
    if (nuevaSeleccion) {
      setValue('nomostra', true);
      setInfoOrdenMatricula(nuevaSeleccion);
    } else {
      setValue('nomostra', false);
      setInfoOrdenMatricula(undefined);
    }
  };
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      correo_electronico: user.email,
      celular: user.phone_number.replace(/^(\+57)/, ''),
      nombres: user.given_name,
      apellidos: user.family_name,
      plazo: 6,
      ...Cache.getItem('requestForm'),
      documento: user['custom:document'],
      terminosCondiciones: undefined,
      tratamientoDatos: undefined,
      centralesRiesgo: undefined,
    },
  });

  const {
    formState: { isSubmitting },
    watch,
    handleSubmit,
    getValues,
    trigger,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: REQUEST_FORM,
  });

  const fetchConstantes = () =>
    ConstantesSolicitudService.get()
      .then((response) => {
        setFormConstants(response);
        Cache.setItem('form-constants', response);
        console.log('p');
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const fetchLastCredit = () =>
    CreditoService.getLast(user.sub)
      .then(async (response) => {
        await reset(
          {
            ...response,
            ...Cache.getItem('requestForm'),
            documento: user['custom:document'],
            terminosCondiciones: undefined,
            tratamientoDatos: undefined,
            centralesRiesgo: undefined,
          },
          { keepDefaultValues: true }
        );
        await setFetchedLastCredit(true);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    fetchLastCredit();
    if (!formConstants) fetchConstantes();
  }, []);

  useEffect(() => {
    if (loading && formConstants && fetchedLastCredit) setLoading(false);
  }, [formConstants, fetchedLastCredit]);

  const storeData = (data) => Cache.setItem('requestForm', data);
  const watchUniversidad = watch('universidad');
  const watchDocument = watch('documento');
  const watchTypeDocument = watch('tipo_documento');

  function obtenerFechaNoVencida(fecha1, fecha2, status, VlPag, VlPagExt) {
    const fechaInicio = moment(fecha1, 'DD/MM/YYYY');
    const fechaFin = moment(fecha2, 'DD/MM/YYYY');
    // Aquí estás estableciendo la fecha de hoy manualmente, para la prueba
    const fechaHoys = moment();
    // Formateando la fecha actual al mismo formato 'DD/MM/YYYY'
    const fechaHoy = moment(fechaHoys.format('DD/MM/YYYY'), 'DD/MM/YYYY');

    if (fechaHoy.isSameOrBefore(fechaInicio)) {
      if (status === 'fecha') {
        return fechaInicio.format('DD/MM/YYYY');
      } else {
        return VlPag;
      }
    }
    if (fechaHoy.isSameOrBefore(fechaFin)) {
      if (status === 'fecha') {
        return fechaFin.format('DD/MM/YYYY');
      } else {
        return VlPagExt;
      }
    }
    return undefined;
  }

  const nextStep = async () => {
    await trigger(undefined, { shouldFocus: true }).then(async (isValid) => {
      if (isValid) {
        // if (formStep === 5) {
        //     setShowCodeudor1(true)
        // }
        if (formStep === 1) {
          setValue('nomostra', false);
          const arrayResp = [];
          // Agregar un mensaje mejor
          if (showLinea === undefined) {
            dialog.info(DIALOG.SelectLineaProcess);
            return;
          }
          if (document.getElementById('universidadSelect').value === '0') {
            dialog.info(DIALOG.SelectUniversity);
            return;
          }
          if (watchUniversidad === '2') {
            setShowMessage(true);
            const body = {
              doc: watchDocument,
              docType: watchTypeDocument,
              rol: 'consult',
            };
            let resp = {
              success: false,
            };
            try {
              resp = await ExternadoService.postConsultExternado(body);
            } catch (e) {
              console.log(e);
            }
            if (resp.success === true) {
              let count = 1;
              if (
                resp.info[0]?.EMensaje?.includes(
                  'No existen órdenes de pago para el '
                )
              ) {
                setValue('nomostra', false);
              } else {
                for (const r of resp.info) {
                  const VlPag = obtenerFechaNoVencida(
                    r.Fecord,
                    r.Fecext,
                    '',
                    r.VlPag,
                    r.VlPagExt
                  );
                  if (
                    (((watchLinea === '2' || watchLinea === '9') &&
                    (r.Origen === 'A' || r.Origen === 'E')) || 
                    (watchLinea === '9' && r.Origen === 'H')) &&
                    VlPag !== undefined &&
                    parseInt(VlPag) > 580000
                  ) {
                    arrayResp.push({
                      id: count,
                      nOrdenMatricula: r.OpbelTest,
                      fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
                      valorPago: VlPag,
                      typeOrigen: r.Txtorigen,
                      typeOrigenText: r.Text,
                      seguro: r.VlSeguro,
                      hide: false,
                      matricula: VlPag - r.VlSeguro,
                    });
                    count++;
                  } else if (
                    watchLinea === '1' &&
                    (r.Origen === 'A' ||
                      r.Origen === 'B' ||
                      r.Origen === 'C' ||
                      r.Origen === 'E' ||
                      r.Origen === 'H' ||
                      r.Origen === 'J' ||
                      r.Origen === 'K') &&
                    VlPag !== undefined &&
                    parseInt(VlPag) > 580000
                  ) {
                    arrayResp.push({
                      id: count,
                      nOrdenMatricula: r.OpbelTest,
                      fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
                      valorPago: VlPag,
                      typeOrigen: r.Txtorigen,
                      typeOrigenText: r.Text,
                      seguro: r.VlSeguro,
                      hide: false,
                      matricula: VlPag - r.VlSeguro,
                    });
                    count++;
                  }
                }
              }
            }
          }
          Cache.setItem('ordenList', arrayResp);
          setValue('arrayResp', arrayResp);
        }
        if (formStep === 2 && watchUniversidad === '2') {
          if (
            infoOrdenMatricula === undefined &&
            getValues('arrayResp').length >= 1
          ) {
            dialog.info(DIALOG.SelectOMProcess);
            return;
          }
          if (Cache.getItem('ordenList').length === 0) {
            dialog.info(DIALOG.UpdateArchiveProcessAPI);
            return;
          }
        }
        // comment this lines to make the file upload no required
        if (
          formStep === 3 &&
          !uploadProps.fileData &&
          Number(watchUniversidad) !== 2
        ) {
          dialog.info(DIALOG.UpdateArchiveProcess);
          return;
        }
        await storeData(getValues());
        if (formStep >= middleStep + 1) {
          checkScore(false);
        }
        setFormStep((current) =>
          current < steps.length - 1 ? current + 1 : current
        );
      }
    });
  };

  const prevStep = async () => {
    if (formStep === 2) {
      setShowLinea(undefined);
      setInfoOrdenMatricula(undefined);
      setShowMessage(false);
    }
    if (formStep === 3) {
      setInfoOrdenMatricula(undefined);
    }
    if (watchUniversidad === '2' && formStep === 4) {
      if (infoOrdenMatricula) {
        setValue('nomostra', true);
      }
    } else {
      setValue('nomostra', false);
    }
    await storeData(getValues());
    if (formStep > middleStep + 1) {
      checkScore(false);
    }
    setFormStep((current) => (current > 0 ? current - 1 : current));
  };
  // const createZapsign = async (creditId) => {
  //   const body = {
  //     creditId,
  //     rol: 'createRegister',
  //   };
  //   await ZapsignService.postZapsign(body);
  // };
  // const validateStateHistoryStateEtapa = async (creditId) => {
  //   const body = {
  //     creditId,
  //     rol: 'validateStateHistoryStateEtapa',
  //   };
  //   await ExternadoService.validateStateHistoryStateEtapa(body);
  // };
  const createHistorialEstadoEtapas = async (creditId) => {
    const body = {
      creditId: creditId,
      message: 'Cerrado automatico para abrir paso 2',
      status: 2,
      etapa: 1,
      rol: 'insertHistorialEstadoEtapas',
    };
    await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };

    const createCrmHubspot = async (creditId) => {
      try {
        const body = {
          creditId,
          rol: 'createContact',
        };
        return await HubspotCrmService.postHubspot(body);
      } catch (error) {
        return error;
      }
      
    };

  // const cambiarEstadoFabricaSP = async (creditId) => {
  //   try {
  //     const body = {
  //       loanIds: creditId,
  //       statusActual: 11,
  //       rol: 'cambiarEstadoFabricaSP',
  //     };
  //     return await ConsultGeneral.postInsertHistorialEstadoEtapas(body);
  //   } catch (error) {
  //     return error;
  //   }
    
  // };
  const updateEstado = async (numero) => {
    // const body = {
    //   creditId: numero,
    //   rol: 'status',
    // };
    // await ExternadoService.postStatusExternado(body).then((response1) => {
    //   console.log(response1);
    // });
    await  EtapaService.update({
      creditoNumero: numero,
      etapas: [
        {
          numero: ETAPAS.VERIFICACION,
          estadoId: ESTADOS_ETAPA.ENABLED,
        },
      ],
    })
      .then((response) => {
        setEtapas((current) => ({ ...current, ...response }));
        console.log('====================================');
        console.log(etapas);
        console.log('====================================');
      })
      .catch((error) => {
        console.error(error);
      });
    const bodyHubspot = {
      creditId: numero,
      rol: 'updateEtapa',
    };
    await HubspotCrmService.postHubspot(bodyHubspot);
  };

  const updateCreditRenovation = async (response) => {
    try {
      const viewOpen = fetchedConsultDataOpen ? true : false;
      const body = {
        numero: response.numero,
        renovacionSolicitud: viewOpen,
        mismaCondicionRenovacion: isRenovationWhitCondition,
        aceptoRenovacion: acceptedRenovation,
        tipoEstudiante: viewOpen ? 'RE' : Number(response.periodoCursa) === 1 ? 'NU': 'NA' ,
        rol: 'updateCreditRenovation',
      };
      return await ConsultGeneral.postInsertHistorialEstadoEtapas(body);
    } catch (error) {
      return error;
    }
    
  };

  const updateCreditCondonation = async (creditId) => {
    const body = {
      condonacion: 0,
      creditId: creditId,
      rol: 'updateCreditCondonation',
    };
    await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const onSubmit = async (values, e) => {
    e.preventDefault();
    const lineasArray = Cache.getItem('ordenList');
    if (lineasArray.length > 0) {
      values.valor_matricula = lineasArray[0].valorPago;
    }
    setShowAuthorization(false);
    if (!checkScore(true)) return;
    await storeData(values);

    if(String(values.linea) === '9') {
      values.valor_financiar = Cache.getItem('valorFinanciar_andina');
      values.plazo = Cache.getItem('plazo_andina');
    }
    if(String(values.linea) === '12') {
      // values.valor_financiar = Cache.getItem('valorFinanciar_chicamocha');
      values.condonacion = 0;
    }
    const params = transformFormData({
      values,
      userId: user.sub,
      disabled: watchDisabled,
      numRef,
    });
    setLoading(true);
    await CreditoService.post(params)
      .then(async (response) => {
        Cache.setItem('requestCredit', response);
        console.log('response', response);
        if (uploadProps.fileData) {
          await ArchivoService.getAll({
            creditoNumero: response?.numero,
            tipoId: 'ORDEN_MATRICULA',
          })
            .then(async (response) => {
              if (response.estudiante.archivos[0]?.id !== undefined) {
                // WITH THIS NEW CODE
                const key = `creditos/${response.creditoNumero}/${uploadProps.fileData.prefix ? uploadProps.fileData.prefix + '/' : ''}${uploadProps.fileData.name}`;
                
                const command = new PutObjectCommand({
                  Bucket: process.env.REACT_APP_BUCKET,
                  Key: key,
                  Body: uploadProps.fileData,
                  ContentType: 'application/pdf',
                  CacheControl: 'no-cache'
                });

                await s3Client.send(command);
                ArchivoService.update({
                  archivoId: response.estudiante.archivos[0]?.id,
                  nombre: uploadProps.fileData.name,
                  nombreOriginal: uploadProps.fileData.name,
                  ruta: key,
                  estado: FILE_STATE.UNDER_REVIEW,
                });
              }
            })
            .catch((error) => {
              console.error(error);
              dialog.info(DIALOG.N11, {
                devInfo: error.message || error.code || error.type,
              });
            });
        } else if (infoOrdenMatricula) {
          await ArchivoService.getAll({
            creditoNumero: response.numero,
            tipoId: 'ORDEN_MATRICULA',
          })
            .then(async (response1) => {
              infoOrdenMatricula.incluir_seguro = watch('incluir_seguro');
              const objetoString = JSON.stringify(infoOrdenMatricula);
              if (response1.estudiante.archivos[0].id !== undefined) {
                ArchivoService.update({
                  archivoId: response1.estudiante.archivos[0].id,
                  nombre: infoOrdenMatricula.nOrdenMatricula,
                  nombreOriginal: infoOrdenMatricula.nOrdenMatricula,
                  ruta: null,
                  estado: FILE_STATE.APPROVED,
                  rutaEnBlanco: objetoString,
                });
                const body = {
                  creditId: response.numero,
                  nOrdenMatricula: infoOrdenMatricula.nOrdenMatricula,
                  status: 'signed',
                  informationExtra: infoOrdenMatricula,
                  rol: 'actualizarorden',
                };
                await ExternadoService.postConsultExternado(body);
              }
            })
            .catch((error) => {
              console.error(error);
              dialog.info(DIALOG.N11, {
                devInfo: error.message || error.code || error.type,
              });
            });
        }
        
        // await createZapsign(response.numero);
        // await validateStateHistoryStateEtapa(response.numero);
        if (response.lineaId.toString() === '12') {
          await updateCreditCondonation(response.numero);
        }
        await updateCreditRenovation(response);
        // await cambiarEstadoFabricaSP(response.numero);
        await createCrmHubspot(response.numero);
        await updateEstado(response.numero);
        await createHistorialEstadoEtapas(response.numero);
        // await nextStep();
        setLoading(false);
        navigate('/solicitar-financiacion');
        // Esto hay que mejorarlo pero de momento es una soluccion para que no vuelva a pedir credito
        window.location.reload();
      })
      .catch((error) => {
        console.error('error-CreditoService',error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
        setLoading(false);
      });
  };
  const findInstallment = (term) =>
    installments?.find((e) => parseInt(e.term) === parseInt(term))?.installment;
  const [steps, middleStep, ingresos, egresos, disabled] = useMemo(() => {
    const steps = [
      <InfoEstudiante key="InfoEstudiante" formConstants={formConstants} />,
      <ProgramaFinanciar
        key="ProgramaFinanciar"
        formConstants={formConstants}
        uploadProps={uploadProps}
        onSeleccionChange={handleSeleccionChange}
        fetchedConsultDataOpen = {fetchedConsultDataOpen}
        creditRenovationInfo = {fetchedConsultCreditRenovation}
        showMessage={showMessage}
      />,
      <ProcessFinancingOrden
        key="ProcessFinancingOrden"
        onSeleccionChange={handleSeleccionChangeApi}
        univesidadId={watchUniversidad}
      />,
      <Simulador
        key="FinancialSimulator"
        formConstants={formConstants}
        uploadProps={uploadProps}
        showLinea={showLinea}
        infoOrdenMatricula={infoOrdenMatricula}
      />,
      <ActEcoEstudiante
        key="ActEcoEstudiante"
        formConstants={formConstants}
        numRef={numRef}
      />,
    ];
    const middleStep = steps.length - 1;
    const ingresos = ['ingresos', 'ingresos_alt'];
    const egresos = ['egresos'];
    const disabled = [];
    let disabledInput = false;
    const restCodeudor = watch('ingresos_cod0')
      ? watch('ingresos_cod0') - watch('egresos_cod0')
      : 0;

    for (let i = 0; i < numCodeudores; i++) {
      let option = watch('trabaja');
      const installment = findInstallment(watch('plazo'));
      if (Number(watchTypeDocument) === 1) {
        if (Boolean(option) === true || option === 'true') {
          const egre = watch(ingresos)[0] - Number(watch(egresos)[0]);
          if (egre >= installment) {
            option = true;
          } else {
            option = watchTrabajaFunction(i, restCodeudor, installment);
          }
        } else {
          option = watchTrabajaFunction(i, restCodeudor, installment);
        }
      } else if (Number(watchTypeDocument) === 2) {
        option = watchTrabajaFunction(i, restCodeudor, installment);
      }
      disabledInput = changeDisabledInputFunction(i);

      steps.push(
        <InfoActEcoCodeudor
          key={'InfoActEcoCodeudor' + i}
          formConstants={formConstants}
          number={i}
          optional={option}
          disabledInput={disabledInput}
        />
      );
      ingresos.push(`ingresos_cod${i}`, `ingresos_alt_cod${i}`);
      egresos.push(`egresos_cod${i}`);
      disabled.push(`disabled_cod${i}`);
    }
    const watchDisabled = watch(disabled);
    console.log('watchDisabled');
    console.log(watchDisabled);
    steps.push(
      <Autorizacion
        key={'Autorización'}
        formConstants={formConstants}
        estudiante={{
          nombre: watch('nombres') + ' ' + watch('apellidos'),
          documento: watch('documento'),
        }}
        codedudores={watchDisabled.reduce((total, x, i) => {
          if (!x) {
            total.push({
              nombre:
                watch(`nombres_cod${i}`) + ' ' + watch(`apellidos_cod${i}`),
              documento: watch(`documento_cod${i}`),
            });
            return total;
          }
          return total;
        }, [])}
      />
    );

    return [steps, middleStep, ingresos, egresos, disabled];
  }, [formConstants, uploadProps, numCodeudores, numCodeudoresObl]);

  const watchIngresos = watch(ingresos);
  const watchEgresos = watch(egresos);
  const watchDisabled = watch(disabled);
  const watchTipoDocumento = watch('tipo_documento');
  const watchTrabaja = watch('trabaja');
  const watchLinea = watch('linea');
  
  const reduce = (array, index, isIncome = false) =>
    array
      .filter((_, j) => {
        return (isIncome ? Math.floor(j / 2) : j) <= index;
      })
      .map((value, j) => {
        j = isIncome ? Math.floor(j / 2) : j;
        return !value ||
          (j === 0 && watchTrabaja.toString() !== 'true') ||
          (j > 0 && watchDisabled[j - 1] && numCodeudoresObl < j)
          ? 0
          : Number(value);
      })
      .reduce((a, b) => a + b, 0);

  const getCodeudorChecks = () => {
    const checks = [];
    const installment = findInstallment(watchLinea.toString() === '9'? Cache.getItem('plazo_andina') : watch('plazo'));
    let income, expenses;
    for (let i = 0; i < watchEgresos.length; i++) {
      income = reduce(watchIngresos, i, true);
      expenses = reduce(watchEgresos, i);
      checks.push(income - expenses >= installment);
    }
    return checks;
  };

  const getNumCodeudoresObl = (checks) => {
    let numCodeudoresObl = numCodeudores;
    for (let i = 0; i < checks.length; i++)
      if (checks[i] > 0) {
        numCodeudoresObl = i;
        break;
      }
    if (numCodeudoresObl === 0 && watchTipoDocumento?.toString() === '2')
      numCodeudoresObl = 1;
    return numCodeudoresObl;
  };

  const checkScore = (showDialog) => {
    if (formStep < middleStep) return false;
    const checks = getCodeudorChecks();
    const newNumCodeudoresObl = getNumCodeudoresObl(checks);
    setNumCodeudoresObl(newNumCodeudoresObl);
    if (showDialog) {
      const step = formStep - middleStep;
      if (step === numCodeudores) {
        if (!checks[checks.length - 1]) dialog.info(DIALOG.N3);
      } else if (step < newNumCodeudoresObl) {
        if (step === 0) dialog.info(DIALOG.N2);
        else dialog.info(DIALOG.N5);
      }
    }
    return checks[checks.length - 1];
  };

  // useEffect: watchDisabledCod
  for (let i = 0; i < watchDisabled.length; i++) {
    useEffect(() => {
      const disabledValue = watchDisabled[i];
      if (disabledValue === undefined) return;
      if (
        i < watchDisabled.length - 1 &&
        watchDisabled[i + 1] !== disabledValue
      ) {
        setValue(disabled[i + 1], disabledValue);
      }
    }, [watchDisabled[i]]);
  }

  useEffect(() => {
    for (let i = 0; i < watchDisabled.length; i++)
      if (numCodeudoresObl > i && watchDisabled[i]) {
        setValue(disabled[i], false);
        break;
      }
  }, [numCodeudoresObl]);

  useEffect(() => {
    if (formStep === middleStep) {
      dialog.info(DIALOG.N13);
    }
  }, [formStep]);

  // check if linea is PRO [2]
  const hasNextStep = useMemo(
    () => watchLinea?.toString() === '2',
    [watchLinea]
  );
  return loading ? (
    <Loading />
  ) : (
    <>
      <FormProgressBar
        className="mx-auto max-w-screen-md"
        progress={
          formStep > middleStep ? 1 : (1 / middleStep) * formStep || 0.1
        }
      />
      <FormProvider {...methods}>
        <form
          className="relative my-4 flex flex-1 flex-col pb-9"
          onSubmit={handleSubmit(onSubmit)}
        >
          {steps[formStep]}
          <Dialog
            isOpen={showAuthorization}
            actions={
              <>
                <Button
                  onClick={() => setShowAuthorization(false)}
                  loading={isSubmitting}
                  customColor="bg-custom-red"
                >
                  Cancelar
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  {hasNextStep
                    ? 'Continuar al último paso'
                    : 'Enviar a Estudio'}
                </Button>
              </>
            }
          >
            <p>
              Antes de continuar
              {hasNextStep && ' al último paso'}, es necesario que aceptes y
              autorices:
            </p>
            <div className="flex flex-row items-center">
              <FormInput
                {...InputProps('tratamientoDatos')}
                type="checkbox"
                placeholder={
                  <p>
                    He leído y acepto la{' '}
                    <a
                      className="cursor-pointer font-bold hover:underline"
                      href="https://educacionestrella.com/wp-content/uploads/2021/10/Habeas_Data2021.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ley de Política de Tratamiento de Datos Personales
                    </a>
                  </p>
                }
              />
            </div>
          </Dialog>
          {/* Navigation Buttons */}
          <div className="absolute bottom-0 right-0 w-full lg:w-auto">
            <div className="grid w-full grid-cols-2 gap-4 lg:gap-6">
              {formStep > 0 && formStep < 7 && (
                <Button
                  id={'returnButton'}
                  key="regresar"
                  type="button"
                  onClick={prevStep}
                  className="col-start-1"
                >
                  {'<'} {RETURN}
                </Button>
              )}
              {formStep === 6 && steps.length === 8 && (
                <Button type="submit" loading={isSubmitting}>
                  {hasNextStep ? 'Continuar al último paso' : 'Continuar'}
                </Button>
              )}{' '}
              {formStep < 6 && (
                <Button
                  key="continuar"
                  type="button"
                  onClick={nextStep}
                  className="col-start-2"
                >
                  Continuar {'>'}
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const RequestFormCompRenovation = ({ enMora, fetchedConsultCreditRenovation, fetchedConsultDataOpen = false, acceptedRenovation = false, isRenovationWhitCondition = false }) => {
  const [fetchedLastCredit, setFetchedLastCredit] = useState(false);
  const dialog = useDialog();
  const [loading, setLoading] = useState(true);
  const [formStep, setFormStep] = useState(0);
  const [showAuthorization, setShowAuthorization] = useState(false);
  const [showLinea, setShowLinea] = useState(fetchedConsultCreditRenovation && String(fetchedConsultCreditRenovation.linea.id));
  // const [showMessage, setShowMessage] = useState(false);
  const [etapas, setEtapas] = useState();
  // const [showCodeudor1, setShowCodeudor1] = useState(false);
  const [formConstants, setFormConstants] = useState(
    Cache.getItem('form-constants')
  );
  const [infoOrdenMatricula, setInfoOrdenMatricula] = useState();
  // By changing the value of numCodeudores the form will create the additional steps for each co-signer
  const numRef = 3;
  const navigate = useNavigate();
console.log(enMora);
console.log(fetchedConsultCreditRenovation);
  const { user } = useAuth();
  const uploadProps = useUpload({
    fileName: `${user['custom:document']}_${FILES.ORDEN_MATRICULA.fileName}`,
  });
  // const handleSeleccionChange = (nuevaSeleccion) => {
  //   if (nuevaSeleccion) {
  //     setValue('linea', nuevaSeleccion);
  //     setShowLinea(nuevaSeleccion);
  //   } else {
  //     setShowLinea(undefined);
  //   }
  // };
  const handleSeleccionChangeApi = (nuevaSeleccion) => {
    if (nuevaSeleccion) {
      setValue('nomostra', true);
      setInfoOrdenMatricula(nuevaSeleccion);
    } else {
      setValue('nomostra', false);
      setInfoOrdenMatricula(undefined);
    }
  };
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      correo_electronico: user.email,
      celular: user.phone_number.replace(/^(\+57)/, ''),
      nombres: user.given_name,
      apellidos: user.family_name,
      plazo: 6,
      ...Cache.getItem('requestForm'),
      documento: user['custom:document'],
      terminosCondiciones: undefined,
      tratamientoDatos: undefined,
      centralesRiesgo: undefined,
    },
  });

  const {
    formState: { isSubmitting },
    watch,
    handleSubmit,
    getValues,
    trigger,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: REQUEST_FORM,
  });

  const fetchConstantes = () =>
    ConstantesSolicitudService.get()
      .then((response) => {
        setFormConstants(response);
        Cache.setItem('form-constants', response);
        console.log('p');
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const fetchLastCredit = () =>
    CreditoService.getLast(user.sub)
      .then(async (response) => {
        await reset(
          {
            ...response,
            ...Cache.getItem('requestForm'),
            documento: user['custom:document'],
            terminosCondiciones: undefined,
            tratamientoDatos: undefined,
            centralesRiesgo: undefined,
          },
          { keepDefaultValues: true }
        );
        await setFetchedLastCredit(true);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    fetchLastCredit();
    if (!formConstants) fetchConstantes();
  }, []);

  useEffect(() => {
    if (loading && formConstants && fetchedLastCredit) setLoading(false);
  }, [formConstants, fetchedLastCredit]);

  const storeData = (data) => Cache.setItem('requestForm', data);
  const watchUniversidad = String(fetchedConsultCreditRenovation.universidad.id);
  const watchDocument = String(fetchedConsultCreditRenovation.estudiante.documento);
  const watchTypeDocument = String(fetchedConsultCreditRenovation.estudiante.tipoDocumento.id);
  const watchLinea = String(fetchedConsultCreditRenovation.linea.id);

  

  const nextStep = async () => {
    await trigger(undefined, { shouldFocus: true }).then(async (isValid) => {
      if (isValid) {
        // if (formStep === 5) {
        //     setShowCodeudor1(true)
        // }
        if (formStep === 0) {
          // setValue('nomostra', false);
          let arrayResp = [];
          if (watchUniversidad === '2') {
            // setShowMessage(true);
            setValue('nomostra', true);
            arrayResp =  await consultordenMatricula(watchDocument, watchTypeDocument, watchLinea);
            
          } else {
            setValue('nomostra', false);
          }
          Cache.setItem('ordenList', arrayResp);
          setValue('arrayResp', arrayResp);
        }

        if (formStep === 0 && watchUniversidad === '2') {
          if (
            infoOrdenMatricula === undefined &&
            getValues('arrayResp').length >= 1
          ) {
            dialog.info(DIALOG.SelectOMProcess);
            return;
          }
          if (Cache.getItem('ordenList').length === 0) {
            dialog.info(DIALOG.UpdateArchiveProcessAPI);
            return;
          }
        }

        // comment this lines to make the file upload no required
        if (
          formStep === 1 &&
          !uploadProps.fileData &&
          Number(watchUniversidad) !== 2
        ) {
          dialog.info(DIALOG.UpdateArchiveProcess);
          return;
        }

        
        
        await storeData(getValues());
        setFormStep((current) =>
          current < steps.length - 1 ? current + 1 : current
        );
      }
    });
  };

  const prevStep = async () => {
    if (formStep === 1) {
      setShowLinea(undefined);
      setInfoOrdenMatricula(undefined);
      // setShowMessage(false);
    }
    if (formStep === 3) {
      setInfoOrdenMatricula(undefined);
    }
    if (watchUniversidad === '2' && formStep === 1) {
      if (infoOrdenMatricula) {
        setValue('nomostra', true);
      }
    } else {
      setValue('nomostra', false);
    }
    await storeData(getValues());
    setFormStep((current) => (current > 0 ? current - 1 : current));
  };
  // const createZapsign = async (creditId) => {
  //   const body = {
  //     creditId,
  //     rol: 'createRegister',
  //   };
  //   await ZapsignService.postZapsign(body);
  // };
  // const validateStateHistoryStateEtapa = async (creditId) => {
  //   const body = {
  //     creditId,
  //     rol: 'validateStateHistoryStateEtapa',
  //   };
  //   await ExternadoService.validateStateHistoryStateEtapa(body);
  // };
  const createHistorialEstadoEtapas = async (creditId) => {
    const body = {
      creditId: creditId,
      message: 'Cerrado automatico para abrir paso 2',
      status: 2,
      etapa: 1,
      rol: 'insertHistorialEstadoEtapas',
    };
    await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };

    const createCrmHubspot = async (creditId) => {
      try {
        const body = {
          creditId,
          rol: 'createContact',
        };
        return await HubspotCrmService.postHubspot(body);
      } catch (error) {
        return error;
      }
      
    };

  // const cambiarEstadoFabricaSP = async (creditId) => {
  //   try {
  //     const body = {
  //       loanIds: creditId,
  //       statusActual: 11,
  //       rol: 'cambiarEstadoFabricaSP',
  //     };
  //     return await ConsultGeneral.postInsertHistorialEstadoEtapas(body);
  //   } catch (error) {
  //     return error;
  //   }
    
  // };
  const updateEstado = async (numero) => {
    // const body = {
    //   creditId: numero,
    //   rol: 'status',
    // };
    // await ExternadoService.postStatusExternado(body).then((response1) => {
    //   console.log(response1);
    // });
    await  EtapaService.update({
      creditoNumero: numero,
      etapas: [
        {
          numero: ETAPAS.VERIFICACION,
          estadoId: ESTADOS_ETAPA.ENABLED,
        },
      ],
    })
      .then((response) => {
        setEtapas((current) => ({ ...current, ...response }));
        console.log('====================================');
        console.log(etapas);
        console.log('====================================');
      })
      .catch((error) => {
        console.error(error);
      });
    const bodyHubspot = {
      creditId: numero,
      rol: 'updateEtapa',
    };
    await HubspotCrmService.postHubspot(bodyHubspot);
  };

  const updateCreditRenovation = async (response) => {
    try {
      const viewOpen = fetchedConsultDataOpen ? true : false;
      const body = {
        numero: response.numero,
        renovacionSolicitud: viewOpen,
        mismaCondicionRenovacion: isRenovationWhitCondition,
        aceptoRenovacion: acceptedRenovation,
        tipoEstudiante: viewOpen ? 'RE' : Number(response.periodoCursa) === 1 ? 'NU': 'NA' ,
        rol: 'updateCreditRenovation',
      };
      return await ConsultGeneral.postInsertHistorialEstadoEtapas(body);
    } catch (error) {
      return error;
    }
    
  };

  const onSubmit = async (values, e) => {
    e.preventDefault();
    if (
      formStep === 1 &&
      !uploadProps.fileData &&
      Number(watchUniversidad) !== 2
    ) {
      dialog.info(DIALOG.UpdateArchiveProcess);
      return;
    }
    const lineasArray = Cache.getItem('ordenList');
    if (lineasArray.length > 0) {
      values.valor_matricula = lineasArray[0].valorPago;
    }
    setShowAuthorization(false);
    await storeData(values);
    const params = transformFormDataRenovation({
      values,
      fetchedConsultCreditRenovation,
      userId: user.sub,
      numRef,
    });
    setLoading(true);
    await CreditoService.post(params)
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
        setLoading(false);
      })
      .then(async (response) => {
        Cache.setItem('requestCredit', response);
        if (uploadProps.fileData) {
          await ArchivoService.getAll({
            creditoNumero: response.numero,
            tipoId: 'ORDEN_MATRICULA',
          })
            .then(async (response) => {
              if (response.estudiante.archivos[0]?.id !== undefined) {
                const key = `creditos/${response.creditoNumero}/${uploadProps.fileData.prefix ? uploadProps.fileData.prefix + '/' : ''}${uploadProps.fileData.name}`;
                
                const command = new PutObjectCommand({
                  Bucket: process.env.REACT_APP_BUCKET,
                  Key: key,
                  Body: uploadProps.fileData,
                  ContentType: 'application/pdf',
                  CacheControl: 'no-cache'
                });

                await s3Client.send(command);
                ArchivoService.update({
                  archivoId: response.estudiante.archivos[0]?.id,
                  nombre: uploadProps.fileData.name,
                  nombreOriginal: uploadProps.fileData.name,
                  ruta: key,
                  estado: FILE_STATE.UNDER_REVIEW,
                });
              }
            })
            .catch((error) => {
              console.error(error);
              dialog.info(DIALOG.N11, {
                devInfo: error.message || error.code || error.type,
              });
            });
        } else if (infoOrdenMatricula) {
          await ArchivoService.getAll({
            creditoNumero: response.numero,
            tipoId: 'ORDEN_MATRICULA',
          })
            .then(async (response1) => {
              infoOrdenMatricula.incluir_seguro = watch('incluir_seguro');
              const objetoString = JSON.stringify(infoOrdenMatricula);
              if (response1.estudiante.archivos[0].id !== undefined) {
                ArchivoService.update({
                  archivoId: response1.estudiante.archivos[0].id,
                  nombre: infoOrdenMatricula.nOrdenMatricula,
                  nombreOriginal: infoOrdenMatricula.nOrdenMatricula,
                  ruta: null,
                  estado: FILE_STATE.APPROVED,
                  rutaEnBlanco: objetoString,
                });
                const body = {
                  creditId: response.numero,
                  nOrdenMatricula: infoOrdenMatricula.nOrdenMatricula,
                  status: 'signed',
                  informationExtra: infoOrdenMatricula,
                  rol: 'actualizarorden',
                };
                await ExternadoService.postConsultExternado(body);
              }
            })
            .catch((error) => {
              console.error(error);
              dialog.info(DIALOG.N11, {
                devInfo: error.message || error.code || error.type,
              });
            });
        }
        
        // await createZapsign(response.numero);
        // await validateStateHistoryStateEtapa(response.numero);
        await updateCreditRenovation(response);
        // await cambiarEstadoFabricaSP(response.numero);
        await createCrmHubspot(response.numero);
        await updateEstado(response.numero);
        await createHistorialEstadoEtapas(response.numero);
        // await nextStep();
        setLoading(false);
        navigate('/solicitar-financiacion');
        // Esto hay que mejorarlo pero de momento es una soluccion para que no vuelva a pedir credito
        window.location.reload();
      });
  };
  const [steps, middleStep] = useMemo(() => {
    const steps = [
      <ProcessFinancingOrden
        key="ProcessFinancingOrden"
        onSeleccionChange={handleSeleccionChangeApi}
        univesidadId={watchUniversidad}
      />,
      <Simulador
        key="FinancialSimulator"
        formConstants={formConstants}
        uploadProps={uploadProps}
        showLinea={showLinea}
        infoOrdenMatricula={infoOrdenMatricula}
        fetchedConsultCreditRenovation={fetchedConsultCreditRenovation}
      />,
    ];
    const middleStep = steps.length - 1;
    const ingresos = ['ingresos', 'ingresos_alt'];
    const egresos = ['egresos'];
    const disabled = [];

    return [steps, middleStep, ingresos, egresos, disabled];
  }, [formConstants, uploadProps]);


  useEffect(() => {
    if (formStep === middleStep) {
      dialog.info(DIALOG.N13);
    }
  }, [formStep]);

  // check if linea is PRO [2]
  const hasNextStep = useMemo(
    () => watchLinea?.toString() === '2',
    [watchLinea]
  );
  
  return loading ? (
    <Loading />
  ) : (
    <>
      <FormProgressBar
        className="mx-auto max-w-screen-md"
        progress={
          formStep > middleStep ? 1 : (1 / middleStep) * formStep || 0.1
        }
      />
      <FormProvider {...methods}>
        <form
          className="relative my-4 flex flex-1 flex-col pb-9"
          onSubmit={handleSubmit(onSubmit)}
        >
          {steps[formStep]}
          <Dialog
            isOpen={showAuthorization}
            actions={
              <>
                <Button
                  onClick={() => setShowAuthorization(false)}
                  loading={isSubmitting}
                  customColor="bg-custom-red"
                >
                  Cancelar
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  {hasNextStep
                    ? 'Continuar al último paso'
                    : 'Enviar a Estudio'}
                </Button>
              </>
            }
          >
            <p>
              Antes de continuar
              {hasNextStep && ' al último paso'}, es necesario que aceptes y
              autorices:
            </p>
            <div className="flex flex-row items-center">
              <FormInput
                {...InputProps('tratamientoDatos')}
                type="checkbox"
                placeholder={
                  <p>
                    He leído y acepto la{' '}
                    <a
                      className="cursor-pointer font-bold hover:underline"
                      href="https://educacionestrella.com/wp-content/uploads/2021/10/Habeas_Data2021.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ley de Política de Tratamiento de Datos Personales
                    </a>
                  </p>
                }
              />
            </div>
          </Dialog>
          {/* Navigation Buttons */}
          <div className="absolute bottom-0 right-0 w-full lg:w-auto">
            <div className="grid w-full grid-cols-2 gap-4 lg:gap-6">
              {formStep > 0 && formStep < 2 && (
                <Button
                  id={'returnButton'}
                  key="regresar"
                  type="button"
                  onClick={prevStep}
                  className="col-start-1"
                >
                  {'<'} {RETURN}
                </Button>
              )}
              {formStep === 1 && steps.length === 2 && (
                <Button type="submit" loading={isSubmitting}>
                  {hasNextStep ? 'Continuar al último paso' : 'Continuar'}
                </Button>
              )}{' '}
              {formStep < 1 && (
                <Button
                  key="continuar"
                  type="button"
                  onClick={nextStep}
                  className="col-start-2"
                >
                  Continuar {'>'}
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default function RequestForm() {
  const [fetchedConsultCreditRenovation, setFetchedConsultCreditRenovation] = useState(null);
  const [fetchedConsultDataOpen, setFetchedConsultDataOpen] = useState(null);
  const [enMora, setEnMora] = useState(false);
  const [acceptedRenovation, setAcceptedRenovation] = useState();
  const [ordenMatriculaFetched, setOrdenMatriculaFetched] = useState(null);
  const [isRenovationWhitCondition, setIsRenovationWhitCondition] = useState();
  // const [tipoPeriodo, setTipoPeriodo] = useState('');
  const { user } = useAuth();
  const dialog = useDialog();
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  // const { darkMode } = useDarkMode();

  const fetchCreditRenovation = async (fetchedConsultDataOpen) => {
    if (fetchedConsultDataOpen) {
      const body = {
        numero: fetchedConsultDataOpen.codigo_unico,
        rol: 'consultCreditRenovation',
      };
      const resul = await ConsultGeneral.postConsultCreditRenovation(body);
      setFetchedConsultCreditRenovation(resul);
      return resul;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      let diasMora = false;
      const body = {
        nro_identificacion: user['custom:document'],
        rol: 'consultaOpenCreditosServicio',
      };
      const resul = await OpenCoopCrmService.postOpenCoop(body);
      if (resul?.listCustomer?.length > 0 && !enMora) {
      
        resul?.listCustomer[0].forEach((item) => {
          if(item.dias_mora >= 5) {
            diasMora = true;
            return;
          }
        });
      }
      let consultDataOpenResult = resul === null ? [] : resul?.listCustomer; // Asumiendo que listCustomer es un array
  
      // Verifica si consultDataOpenResult es un array antes de usar map
      let consultDataOpen = [];
      let isRenovation = false;
      if (Array.isArray(consultDataOpenResult)) {
        consultDataOpen = consultDataOpenResult.map(
          subArray => 
          subArray.sort((a, b) => new Date(b.fecha_apertura) - new Date(a.fecha_apertura))
        );

        if (consultDataOpen.length > 0 ) {
          let idCredito = '';
          let fechaValida = '';
          let consultaRenovacion = null;
          let fechaAperturaActual = '';    
          console.log(consultDataOpen, 'consulta open');
                
          
          consultDataOpen[0].forEach(consulta => {
            fechaAperturaActual = new Date(consulta.fecha_apertura);
            
            if (fechaValida === '' && consulta.codigo_unico !== null && consulta.codigo_unico.length > 30) {
              idCredito = consulta.codigo_unico;
              fechaValida = new Date(consulta.fecha_apertura);
              consultaRenovacion = consulta;
            }
            else if (consulta.codigo_unico !== null && consulta.codigo_unico.length > 30 && consulta.codigo_unico === idCredito && fechaAperturaActual < fechaValida) {
              fechaValida = new Date(consulta.fecha_apertura);
              consultaRenovacion = consulta;
            }
          });
          
          consultDataOpenResult = consultDataOpen.length === 0 ? null : consultaRenovacion;
          setFetchedConsultDataOpen(consultDataOpenResult);
          if (consultDataOpen.length > 0 && (consultaRenovacion.codigo_unico !== null && consultaRenovacion.codigo_unico.length > 30)) {            
            const creditRenovationInfo = await fetchCreditRenovation(consultDataOpenResult);
            isRenovation = getRenovation(creditRenovationInfo, fechaValida);
          }
          
          // setOrdenMatriculaFetched(ordenMatricula);
          setIsRenovationWhitCondition(isRenovation);
          
        } else {
          setLoading(false);
        }
      }
      
      if (diasMora >= 5) {
        setEnMora(true);
      }
    };
  
    fetchData();
  }, [user]);

  useEffect(() => {
    fetchCreditRenovation(fetchedConsultDataOpen);
  }, [fetchedConsultDataOpen]);

  useEffect(() => {
    const fetchOrdenMatricula = async () => {
      let arrayResp = [];
      if (fetchedConsultCreditRenovation && fetchedConsultCreditRenovation?.message != 'crédito not found') {
        if (String(fetchedConsultCreditRenovation.universidad.id) === '2') {
          const watchDocument = String(fetchedConsultCreditRenovation.estudiante.documento);
          const watchTypeDocument = String(fetchedConsultCreditRenovation.estudiante.tipoDocumento.id);
          const watchLinea = String(fetchedConsultCreditRenovation.linea.id);
    
          try {
            arrayResp = await consultordenMatricula(watchDocument, watchTypeDocument, watchLinea);
          } catch (error) {
            console.error('Error fetching ordenMatricula: ', error);
          } finally {
            setOrdenMatriculaFetched(true); // Marcar como completado
          }
          
        } else {
          setOrdenMatriculaFetched(true);
        }
        Cache.setItem('ordenList', arrayResp);
      } else {
        setLoading(false);
      }
    };
    fetchOrdenMatricula();
  }, [fetchedConsultCreditRenovation]);

  //Dialog
  useEffect(() => {
    if (!enMora && fetchedConsultCreditRenovation && ordenMatriculaFetched && isRenovationWhitCondition) {
      // dialog.info(
      //   <div className="text-center">
      //     {darkMode ? (
      //       <img src={Renueva27} alt="renueva27Dic" className="h-auto w-full"></img>
      //     ) : (
      //       <img src={Renueva27Oscuro} alt="renueva27DicOscuro" className="h-auto w-full"></img>
      //     )}
      //   </div>,
      //   {
      //     onConfirm: () => {setAcceptedRenovation(false), setLoading(false), navigate('/solicitar-financiacion');}
      //   }
      // );
      dialog.confirmation(
        <div className="text-center">
          {/* <Emoji symbol="warning" className="inline h-12" /> */}
          <img src={background1} alt="background" className="h-auto w-full" />
          <div className='mt-2'>
          </div>
          {fetchedConsultCreditRenovation.codeudores && (fetchedConsultCreditRenovation.codeudores?.map((codeudor, k) => (
            <>
              { k === 0 && (
                <p className='mt-1'>
                <b>Codeudor(es):</b>
                </p>)}
                <div>
                {codeudor.nombre} {codeudor.apellido} {'-'} {codeudor.documento}
                </div>
            </>
          )))}
          <p>
            <div className='mt-2'>
              <b>
              Línea de financiación:
              </b> 
              <div>
                {fetchedConsultCreditRenovation.linea.nombre}
              </div>
            </div>
          </p>
            
          <p>
            <div className='mt-2'>
              <b>
                Nombre del programa:
              </b>
              <div>
                {fetchedConsultCreditRenovation.programa.nombre}
              </div>
            </div>
          </p>

          <p>
            <div className='mt-2 text-[#E7496C]'>
              <b>
                ¿Vas a renovar con esta misma información?
              </b>
            </div>
          </p>
          
        </div>,
        {
          onConfirm: () => {setAcceptedRenovation(true), setLoading(false);},
          onCancel: () => {setAcceptedRenovation(false), setLoading(false);},
          confirmText: 'Sí, usar la misma información',
          cancelText: 'Necesito realizar cambios',
          textBelowActions: <p className='text-center w-full max-w-4xl mx-auto text-light dark:text-dark p-8 pt-0 space-y-4 '>
            (Si tu financiación incluye un <q>Periodo de gracia</q>, 
            los intereses corrientes empiezan a contar desde que desembolsamos el dinero a la Universidad, 
            es decir que aunque no los pagues de inmediato, estos intereses se suman y se cobran al iniciar tus pagos.)
        </p>
        }
        //{textBelowActions: 'Holaa'}
      );
      
    //console.log(textBelowActions);
    }
  }, [fetchedConsultCreditRenovation, ordenMatriculaFetched, enMora, dialog]);

  return loading ? (
    <Loading />
  ) : (
    <InstallmentProvider>
      {!enMora && fetchedConsultCreditRenovation && acceptedRenovation && isRenovationWhitCondition ? (
        <RequestFormCompRenovation 
          enMora={enMora}
          fetchedConsultCreditRenovation={fetchedConsultCreditRenovation}
          fetchedConsultDataOpen={fetchedConsultDataOpen}
          acceptedRenovation={acceptedRenovation}
          isRenovationWhitCondition={isRenovationWhitCondition}
        />
      ) : (!acceptedRenovation || !isRenovationWhitCondition) && (
        <RequestFormComp 
          fetchedConsultDataOpen={fetchedConsultDataOpen}
          acceptedRenovation={acceptedRenovation}
          isRenovationWhitCondition={isRenovationWhitCondition}
          fetchedConsultCreditRenovation={fetchedConsultCreditRenovation}
        />
      )}
    </InstallmentProvider>
  );
}

function watchTrabajaFunction(number, restCodeudor, installment) {
  let watchTrabaja = false;
  if (number > 0 && restCodeudor >= installment) {
    watchTrabaja = true;
  }
  return watchTrabaja;
}
function changeDisabledInputFunction(number) {
  let disabledInput = false;
  if (document.getElementsByName(`disabled_cod${number}`).length > 0) {
    if (document.getElementsByName(`disabled_cod${number}`)[0].checked) {
      disabledInput = true;
    }
    return disabledInput;
  }
}
function obtenerFechaNoVencida(fecha1, fecha2, status, VlPag, VlPagExt) {
  const fechaInicio = moment(fecha1, 'DD/MM/YYYY');
  const fechaFin = moment(fecha2, 'DD/MM/YYYY');
  // Aquí estás estableciendo la fecha de hoy manualmente, para la prueba
  const fechaHoys = moment();
  // Formateando la fecha actual al mismo formato 'DD/MM/YYYY'
  const fechaHoy = moment(fechaHoys.format('DD/MM/YYYY'), 'DD/MM/YYYY');

  if (fechaHoy.isSameOrBefore(fechaInicio)) {
    if (status === 'fecha') {
      return fechaInicio.format('DD/MM/YYYY');
    } else {
      return VlPag;
    }
  }
  if (fechaHoy.isSameOrBefore(fechaFin)) {
    if (status === 'fecha') {
      return fechaFin.format('DD/MM/YYYY');
    } else {
      return VlPagExt;
    }
  }
  return undefined;
}
const consultordenMatricula = async (watchDocument, watchTypeDocument, watchLinea) => {
  const arrayResp = [];
  const body = {
    doc: watchDocument,
    docType: watchTypeDocument,
    rol: 'consult',
  };
  let resp = {
    success: false,
  };
  try {
    resp = await ExternadoService.postConsultExternado(body);
  } catch (e) {
    console.log(e);
  }
  if (resp.success === true) {
    let count = 1;
    if (
      resp.info[0]?.EMensaje?.includes(
        'No existen órdenes de pago para el '
      )
    ) {
      // setValue('nomostra', false);
    } else {
      for (const r of resp.info) {
        const VlPag = obtenerFechaNoVencida(
          r.Fecord,
          r.Fecext,
          '',
          r.VlPag,
          r.VlPagExt
        );
        if (
          watchLinea === '2' &&
          (r.Origen === 'A' || r.Origen === 'E') &&
          VlPag !== undefined &&
          parseInt(VlPag) > 580000
        ) {
          arrayResp.push({
            id: count,
            nOrdenMatricula: r.OpbelTest,
            fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
            valorPago: VlPag,
            typeOrigen: r.Txtorigen,
            typeOrigenText: r.Text,
            seguro: r.VlSeguro,
            hide: false,
            matricula: VlPag - r.VlSeguro,
          });
          count++;
        } else if (
          watchLinea === '1' &&
          (r.Origen === 'A' ||
            r.Origen === 'B' ||
            r.Origen === 'C' ||
            r.Origen === 'E' ||
            r.Origen === 'H' ||
            r.Origen === 'J' ||
            r.Origen === 'K') &&
          VlPag !== undefined &&
          parseInt(VlPag) > 580000
        ) {
          arrayResp.push({
            id: count,
            nOrdenMatricula: r.OpbelTest,
            fecha: obtenerFechaNoVencida(r.Fecord, r.Fecext, 'fecha'),
            valorPago: VlPag,
            typeOrigen: r.Txtorigen,
            typeOrigenText: r.Text,
            seguro: r.VlSeguro,
            hide: false,
            matricula: VlPag - r.VlSeguro,
          });
          count++;
        }
      }
    }
  }
  return arrayResp;
};

function getRenovation(creditRenovationInfo, fechaValida) {

  let isRenovation = false;
  const fechaActual = new Date();
  const fechaCredit = fechaValida;
  
  // Obtiene el año, mes y día
  const anio = fechaActual.getFullYear();
  const mes = fechaActual.getMonth() + 1; // El mes se indexa desde 0, así que se suma 1
  const dia = fechaActual.getDate();
  
  const anioCredit = fechaCredit.getFullYear();
  const mesCredit = fechaCredit.getMonth() + 1;

  // Muestra los resultados
  console.log('Año:', anio);
  console.log('Mes:', mes);
  console.log('Día:', dia);
  console.log('añoCredit:', anioCredit);
  console.log('mesCredit:', mesCredit);
  
  if ((mes >= 10 && mes <= 12) || (mes >= 1 && mes <= 3)) {     
    // Temporada 1: Octubre a Marzo
    if (creditRenovationInfo?.programa?.tipoPeriodo?.valor !== 'Año') {  
      if ((mes >= 10 && mes <= 12) && (mesCredit >= 4 && mesCredit <= 12 && anio === anioCredit)) {
        isRenovation = true;
      }
      else if (
        (mes >= 1 && mes <= 3) && ((mesCredit >= 4 && mesCredit <= 12 && anio - 1 === anioCredit) || (mesCredit >= 1 && mesCredit <= 3 && anio === anioCredit))
      ) {
        isRenovation = true;
      }
    } else if (creditRenovationInfo.programa.tipoPeriodo.valor === 'Año') {
      if (
        (mes >= 10 && mes <= 12) && ((mesCredit >= 10 && mesCredit <= 12 && anio - 1 === anioCredit) || (mesCredit >= 1 && mesCredit <= 3 && anio === anioCredit))
      ) {
        isRenovation = true;
      }
      else if (
        (mes >= 1 && mes <= 3) && ((mesCredit >= 10 && mesCredit <= 12 && anio - 2 === anioCredit) || (mesCredit >= 1 && mesCredit <= 3 && anio - 1 === anioCredit))
      ) {
        isRenovation = true;          
      }
    }
  }
  if ((mes >= 4 && mes <= 9)) {
    // Temporada 2: Abril a Septiembre
    if (creditRenovationInfo.programa.tipoPeriodo.valor !== 'Año') {
      if ((mesCredit >= 1 && mesCredit <= 9) && anio === anioCredit) {
        isRenovation = true;
      }
      else if ((mesCredit >= 10 && mesCredit <= 12) && anio - 1 === anioCredit) {
        isRenovation = true;
      }
    } else if (creditRenovationInfo.programa.tipoPeriodo.valor === 'Año') {
      if ((mesCredit >= 4 && mesCredit <= 9) && anio - 1 === anioCredit) {
          isRenovation = true;
      }
    }
  }
  return isRenovation;
}

