import { FORM } from '../../constants/form/dynamic-form';
import { API } from 'aws-amplify';

const postAnswers = body =>
	API.post('estrella', '/answers', { body });

const getAnswers = loanId =>
	API.get('estrella', `/answers/loans/${loanId}`);

const getForm = (line) => Promise.resolve(FORM(line));

const FormService = {
	getForm,
	getAnswers,
	postAnswers,
};

export default FormService;
