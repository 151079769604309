import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth-context';
import { useDialog } from './dialog-context';
// import EtapaService from '../services/stage/etapa-service';
import { DIALOG } from '../constants/dialog/dialog';
// import CreditoService from '../services/credit/credito-service';
// import { Cache } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { selectStagesData, selectStagesError, selectStagesStatus } from '../features/stages/stagesSelectors';
import { fetchStages } from '../features/stages/stagesThunks';
import { IS_FAILED, IS_SUCCEEDED } from '../constants/redux/states';
import { fetchCredit } from '../features/credit/creditThunks';

const StepContext = createContext({
  step: {},
  setStep: () => {},
  fetchStep: () => {},
});

export function StepProvider({ children }) {

  /**
   * hooks declaration
   */
  const [step, setStep] = useState({});
  const dialog = useDialog();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const stagesData = useSelector(selectStagesData);
  const stagesStatus = useSelector(selectStagesStatus);
  const stagesError = useSelector(selectStagesError);

  async function consultCredit(loanId) {
    if (loanId !== undefined) {
      console.log('loanId', loanId);
      dispatch(fetchCredit(loanId));
    }
  }

  useEffect(() => {
    if (user) {
      dispatch(fetchStages({ user }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (stagesStatus === IS_SUCCEEDED && stagesData) {
      setStep(stagesData);
      consultCredit(stagesData.loanId);
      return;
    } 
    if (stagesStatus === IS_FAILED) {
      dialog.info(DIALOG.N11, {
        devInfo: stagesError.message || stagesError.code || stagesError.type,
      });
    }
  }, [stagesStatus, stagesData, stagesError]);

  const value = { step, setStep };
  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
}

export function useStep() {
  const context = useContext(StepContext);
  if (!context) throw new Error('useStep must be used within StepProvider');
  return context;
}
