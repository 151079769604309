import { createSlice } from '@reduxjs/toolkit';
import { fetchLoanSimulation, createLoanSimulation } from './loanSimulationThunks';

const loanSimulationSlice = createSlice({
  name: 'loanSimulation',
  initialState: {
    data: null, 
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Caso para la consulta (GET)
    builder
      .addCase(fetchLoanSimulation.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(fetchLoanSimulation.fulfilled, (state, action) => {
        state.status = 'succeeded'; 
        state.data = action.payload; 
      })
      .addCase(fetchLoanSimulation.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.payload; 
      });

    
    builder
      .addCase(createLoanSimulation.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(createLoanSimulation.fulfilled, (state, action) => {
        state.status = 'succeeded'; 
        state.data = action.payload; 
      })
      .addCase(createLoanSimulation.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.payload; 
      });
  },
});

export default loanSimulationSlice.reducer;