import { createAsyncThunk } from '@reduxjs/toolkit';
import LoanSimulationService from '../../services/process/loan-simulation-service';

// Thunk para la consulta
export const fetchLoanSimulation = createAsyncThunk(
  'loanSimulation/fetchLoanSimulation',
  async ({ universityId, amount, taxCategory }, thunkAPI) => {
    try {
      const response = await LoanSimulationService.get({ universityId, amount, taxCategory });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Thunk para la creación
export const createLoanSimulation = createAsyncThunk(
  'loanSimulation/createLoanSimulation',
  async (loanSimulationData, thunkAPI) => {
    try {
      const response = await LoanSimulationService.create(loanSimulationData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);