import { createSlice } from '@reduxjs/toolkit';
import { getFormThunk, getAnswersThunk, postAnswersThunk } from './formThunks';

const formSlice = createSlice({
  name: 'form',
  initialState: {
    form: null,
    answers: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getForm
      .addCase(getFormThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFormThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.form = action.payload;
      })
      .addCase(getFormThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // getAnswers
      .addCase(getAnswersThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAnswersThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.answers = action.payload;
      })
      .addCase(getAnswersThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // postAnswers
      .addCase(postAnswersThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postAnswersThunk.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(postAnswersThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default formSlice.reducer;