import { createSlice } from '@reduxjs/toolkit';
import { fetchFiles } from './fileThunks';

const fileSlice = createSlice({
  name: 'files',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';  
        state.data = action.payload;  
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.status = 'failed'; 
        state.error = action.payload; 
      });
  },
});

export default fileSlice.reducer;