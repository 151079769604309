import { API, Auth } from 'aws-amplify';

const getAll = ({ etapaId, creditoNumero, usuarioId, tipoId }) =>
  API.get('estrella', '/archivos', {
    queryStringParameters: {
      ...(etapaId && { etapaId }),
      ...(creditoNumero && { creditoNumero }),
      ...(usuarioId && { usuarioId }),
      ...(tipoId && { tipoId }),
    },
  });

const update = ({
  archivoId,
  nombre,
  nombreOriginal,
  ruta,
  rutaEnBlanco,
  tipoId,
  estado,
  mensaje,
}) => {
  API.put('estrella', `/archivos/${archivoId}`, {
    body: {
      archivo: {
        ...(nombre && { nombre }),
        ...(nombreOriginal && { nombreOriginal }),
        ...(ruta && { ruta }),
        ...(rutaEnBlanco && { rutaEnBlanco }),
        ...(tipoId && { tipoId }),
      },
      ...(estado && { estado }),
      ...(mensaje && { mensaje }),
    },
  });
};
const postArchivoCreate = (creditoNumero, tipoId) =>
  API.post('estrella', '/archivos', {
    body: {
      creditoNumero: creditoNumero,
      tipoId: tipoId,
      codeudorId: null,
    },
  });

const postStudentFileUpload = async (loanId, fileName, prefix, file, onProgress) => {
  try {
    const apiUrl = await API.endpoint('estrella');
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          reject(new Error(xhr.statusText || 'Upload failed'));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Network error occurred'));
      };

      const url = `${apiUrl}/archivos/upload-student-file?${new URLSearchParams({
        loanId,
        fileName,
        prefix: prefix || ''
      })}`;

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', `${token}`);
      xhr.setRequestHeader('X-Cognito-Token', `${token}`);
      xhr.setRequestHeader('Content-Type', 'application/pdf');
      xhr.send(file);
    });
  } catch (error) {
    console.error('Upload setup error:', error);
    throw error;
  }
};


const ArchivoService = {
  getAll,
  update,
  postArchivoCreate,
  postStudentFileUpload
};

export default ArchivoService;
