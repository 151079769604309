import { useState } from 'react';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { FILE_STATE } from '../constants/files/fileState';
import ArchivoService from '../services/file/archivo-service';

const s3Client = new S3Client({
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET
  }
});

const useUpload = ({ loanId, fileId, fileName, prefix }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileData, setFileData] = useState();
  const [fileInfo, setFileInfo] = useState({
    loanId,
    fileId,
    fileName,
    prefix,
  });

  const upload = async (props = {}) => {
    const currentFileInfo = { ...fileInfo, ...props };
    setFileInfo(currentFileInfo);
    
    if (!currentFileInfo.loanId || !currentFileInfo.fileName) {
      throw new Error('Debes proporcionar la información del archivo antes de enviar');
    }

    if (!fileData) {
      throw new Error('Debes cargar un archivo antes de enviar');
    }

    try {
      const key = `creditos/${currentFileInfo.loanId}/${currentFileInfo.prefix ? currentFileInfo.prefix + '/' : ''}${currentFileInfo.fileName}`;
      // Use the Upload utility instead of PutObjectCommand
      const upload = new Upload({
        client: s3Client,
        params: {
          Bucket: process.env.REACT_APP_BUCKET,
          Key: key,
          Body: fileData,
          ContentType: 'application/pdf'
        }
      });

      // Add progress listener
      upload.on('httpUploadProgress', (progress) => {
        const percent = Math.round((progress.loaded * 100) / progress.total);
        setUploadProgress(percent);
      });

      // Wait for upload to complete
      await upload.done();

      if (currentFileInfo.fileId) {
        await ArchivoService.update({
          archivoId: currentFileInfo.fileId,
          nombre: currentFileInfo.fileName + '.pdf',
          nombreOriginal: fileData.name,
          ruta: key,
          estado: FILE_STATE.UNDER_REVIEW,
        });
      }

      return key;
    } catch (error) {
      console.error('Upload error:', error);
      setUploadProgress(0);
      throw new Error('Error al subir el archivo');
    }
  };

  const load = (file) => {
    if (file.type !== 'application/pdf') {
      throw new Error('Solo se permiten archivos PDF');
    }

    if (file.size > 10 * 1024 * 1024) {
      throw new Error('El archivo es demasiado grande');
    }

    setFileData(file);
    setUploadProgress(0);
  };

  const clearData = () => {
    setFileData(undefined);
    setUploadProgress(0);
  };

  return { 
    upload, 
    load, 
    clearData, 
    uploadProgress,
    fileData, 
    setFileInfo 
  };
};

export default useUpload;