import { API } from 'aws-amplify';

const getPaymentInfo = async ({ loanId, userId, amount }) =>
	await API.get('estrella', '/student/payu/current-payment-info', {
		queryStringParameters: { loanId, userId, amount },
	});

const PayUService = {
	getPaymentInfo,
};

export default PayUService;
