import { createAsyncThunk } from '@reduxjs/toolkit';
import PayUService from '../../services/payment/payu-service';

export const fetchPaymentInfo = createAsyncThunk(
  'payment/fetchPaymentInfo',
  async ({ loanId, userId, amount }, thunkAPI) => {
    try {
      const response = await PayUService.getPaymentInfo({ loanId, userId, amount });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message || error?.code || error?.type);
    }
  }
);