import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

export const fetchFiles = createAsyncThunk(
  'files/fetchFiles', 
  async ({ etapaId, creditoNumero, usuarioId, tipoId }, thunkAPI) => {
    try {
      // Hacer la petición a la API utilizando Amplify
      const response = await API.get('estrella', '/archivos', {
        queryStringParameters: {
          ...(etapaId && { etapaId }),
          ...(creditoNumero && { creditoNumero }),
          ...(usuarioId && { usuarioId }),
          ...(tipoId && { tipoId }),
        },
      });
      return response; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);