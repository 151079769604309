import { createAsyncThunk } from '@reduxjs/toolkit';
import HubspotCrmService from '../../services/autorization/hubspotCrm-service';

// Thunk para enviar datos a HubSpot
export const postHubspotData = createAsyncThunk(
  'hubspot/postHubspotData',
  async (body, { rejectWithValue }) => {
    try {
      const response = await HubspotCrmService.postHubspot(body);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);