import React, { useState, } from 'react';
import PseIcon from '../../assets/images/financial-statement/pse.png';
import CashIcon from '../../assets/images/financial-statement/cash.png';
// import Button from '../DynamicForm/Button.jsx';
import { useAuth } from '../../context/auth-context';
import SucButton from '../../assets/images/dialog/sucursalB.svg';
import CorrButton from '../../assets/images/dialog/corresponsalB.svg';
import CajButton from '../../assets/images/dialog/cajeroA.svg';
import Cajero from '../../assets/images/dialog/Cajero.png';
import Corresponsal from '../../assets/images/dialog/Corresponsal.png';
import Sucursal from '../../assets/images/dialog/Sucursal.png';
import { PAYMENT_TYPE_CASH, PAYMENT_TYPE_PSEE, SELECT_OPTION_TEXT } from '../../constants/dialog/components/paymentDialog.constants';

const PaymentModal = (/*{ loanId, fullAmount = '0' }*/) => {
  const [state, setState] = useState('paymentMethod');
  const { user } = useAuth();
  console.log(user);

  // const { InputProps } = useInput({
  //   errors,
  //   getValues,
  //   register,
  //   check,
  //   validations: PAYMENT(!customAmount),
  // });

  const onButtonClick = () => {
    // eslint-disable-next-line no-undef
    const pdfUrl = process.env.PUBLIC_URL + '/CarruselBancolombia-Optimizado.pdf'; // Ruta del PDF en la carpeta public
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'instructivo.pdf'); // Nombre del archivo que se descargará
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const rederPaymentMethod = (
    <>
      <p className="font-bold">Realizar pago por</p>
      <p className="text-xs">{SELECT_OPTION_TEXT}</p>
      <div className="mt-8 grid grid-cols-2 gap-6">
        <a href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=11509">
          <button
            type="button"
            className="flex h-full w-full flex-col items-center justify-between"
          >
            <img src={PseIcon} alt="PSE" />
            <span className="text-xs">{PAYMENT_TYPE_PSEE}</span>
          </button>
        </a>
        <button
          type="button"
          className="flex flex-col items-center justify-between"
          onClick={() => setState('paymentAmount')}
        >
          <img src={CashIcon} alt="Efectivo" />
          <span className="text-xs">{PAYMENT_TYPE_CASH}</span>
        </button>
      </div>
    </>
  );
  const renderPaymentAmount = (
    <>
      <h2 className="pb-5 text-center text-2xl font-bold">{PAYMENT_TYPE_CASH}</h2>
      <p className='pb-3 text-center'><b>Paga a través de nuestro convenio Bancolombia:</b></p>
      <p className="text-center text-sm">{SELECT_OPTION_TEXT}</p>
      <div className="my-8 flex flex-col space-y-2">
        <div className="grid grid-cols-3 p-1">
          <button
            type="button"
            className="mr-3 flex flex-col items-center justify-between"
            onClick={() => setState('corresponsal')}
          >
            <img src={CorrButton} alt="Boton Corresponsal" />
            <span className="text-xs">Corresponsal bancario <br /> Bancolombia</span>
          </button>
          <button
          type="button"
          className="mx-4 flex flex-col items-center justify-between"
          onClick={() => setState('cajero')}
          >
            <img src={CajButton} alt="Boton Cajero" />
            <span className="text-xs">Cajero multifuncional <br /> Bancolombia</span>
          </button>
          <button
          type="button"
          className="ml-4 flex flex-col items-center justify-between"
          onClick={() => setState('sucursal')}
          >
            <img src={SucButton} alt="Boton Sucursal" />
            <span className="text-xs">Sucursal u oficina <br /> Bancolombia</span>
          </button>
        </div>
      </div>
      <div>
        <p className="text-sm">
          Más de 24 mil puntos físicos para realizar tus pagos. {' '}
          <button onClick={onButtonClick}><u> Descarga instructivo en PDF</u></button>
          
        </p>
      </div>

      {/* <div className="grid grid-cols-2 gap-4">
        <Button
          onClick={() => setState('sucursal')}
          customColor="dark:bg-custom-green bg-custom-blue"
        >
          Regresar
        </Button>
        <Button
          
          customColor="bg-custom-purple"
        >
          Pagar
        </Button>
      </div> */}
    </>
  );

  const renderCorresponsal = (
    <>
      <h2 className="pb-5 text-center text-2xl font-bold">Corresponsal bancario Bancolombia</h2>
      <img src={Corresponsal} alt="ImgCorresponsal"></img>
    </>
  );

  const renderCajero = (
    <>
      <h2 className="pb-5 text-center text-2xl font-bold">Cajero multifuncional Bancolombia</h2>
      <img src={Cajero} alt="ImgCajero"></img>
    </>
    
  );

  const renderSucursal = (
    <>
      <h2 className="pb-5 text-center text-2xl font-bold">Sucursal u oficina Bancolombia</h2>
      <img src={Sucursal} alt="ImgSucursal"></img>
    </>
    
  );

  const body = {
    paymentMethod: rederPaymentMethod,
    paymentAmount: renderPaymentAmount,
    corresponsal: renderCorresponsal,
    cajero: renderCajero,
    sucursal: renderSucursal
  };

  return (
    <div className="mx-12 mb-6 flex flex-col items-center justify-center ">
      {body[state]}
    </div>
  );
};

export default PaymentModal;
