import { createAsyncThunk } from '@reduxjs/toolkit';
import SignatureService from '../../services/process/signature-service';

// Thunk para obtener la información de la firma
export const fetchSignature = createAsyncThunk(
  'signature/fetchSignature',
  async ({ creditoNumero, usuarioId }, { rejectWithValue }) => {
    try {
      const response = await SignatureService.get({ creditoNumero, usuarioId });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);