import Button from '../components/DynamicForm/Button';
import useInput from '../hooks/use-input';
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/auth-context';
import useTermSlider from '../hooks/use-term-slider';
import { SIMULATOR } from '../constants/form/validations';
import { Cache } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import Emoji from '../components/shared/Emoji';
import { useNavigate } from 'react-router-dom';
import ZapsignService from '../services/process/zapsign-service';
import { useDialog } from '../context/dialog-context';
import { formatCurrency } from '../utils/format';
import ConsultGeneral from '../services/consult/consult-general';
import ArchivoService from '../services/file/archivo-service';
import Loading from '../components/shared/Loading';

const AndinaVariant = ({ universityId }) => {
  const { user } = useAuth();
  const { TermSlider, calculate } = useTermSlider();
  const [porcentajeCondonacion, setPorcentajeCondonacion] = useState();
  const navigate = useNavigate();
  const dialog = useDialog();
  const cacheCredit = Cache.getItem('requestCredit');
  const [ordenMatricula, setOrdenMatricula] = useState();
  const [btnAcceptAndina, setBtnAcceptAndina] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: user ? user.given_name + ' ' + user.family_name : undefined,
      phone: user?.phone_number.replace(/^(\+57)/, ''),
      email: user?.email,
      universityId,
      term: 7,
    },
  });

  const term = watch('term');
  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: SIMULATOR,
  });    
  const fetchCondonationPercentage = async () => {
    const body = {
      creditoId: cacheCredit.numero,
      estudiantes: '',
      jornadas: '',
      programas: '',
      niveles_academicos: '',
      universidades: '',
      lineas: '',
      estados_credito: '',
      rol: 'getAllCredit',
    };
    try {
      const resul = await ConsultGeneral.postConsultCreditRenovation(body);
      setPorcentajeCondonacion(resul[0].condonacion);
      console.log(resul[0].condonacion);

    } catch (err) {
      console.error('Error: ', err);
    }
    
  };
  useEffect(() => {

    fetchCondonationPercentage();
  }, []);

  const onSubmit = async () => {
    const valorFinanciar = (ordenMatricula?.valorPago)-(ordenMatricula?.matricula*(porcentajeCondonacion/100));
    try {
      await calculate({
        universityId,
        amount: valorFinanciar,
        taxCategory: 'andina',
      });
      setBtnAcceptAndina(true);

    } catch (error) {
      setBtnAcceptAndina(false);
      console.error('Error: ', error);
    }
  };

  const sendAndinaVariant = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 8,
      valorFinanciar: (ordenMatricula?.valorPago)-(ordenMatricula?.matricula*(porcentajeCondonacion/100)),
      plazo: term,
      valorFinanciarAnterior: cacheCredit.valorFinanciar,
      newLine: 9,
      oldLine: 2,
      mensaje:
        'Aceptado por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeLineStudent',
    };

    await ZapsignService.postZapsign(body)
      .then(() => {
        navigate('/solicitar-financiacion');
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  };
  const sendAndinaVariantCancel = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 6,
      mensaje:
        'Desistido por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeLineStudentCancel',
    };

    await ZapsignService.postZapsign(body)
      .then(async () => {
        const bodyCancel = {
          application_id: cacheCredit.numero,
          final_status: 6,
          final_message: 
          'Desistido por: ' +
            user.given_name +
            ' ' +
            user.family_name +
            ' ' +
            user.email,
          previous_status: cacheCredit.estado.id,
          rol: 'updateOrInsertFinalApplicationStatus',
        };
        await ConsultGeneral.postInsertHistorialEstadoEtapas(bodyCancel)
          .then(() => {
            navigate('/solicitar-financiacion');
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  };
  const showDialog = (message, callback) => {
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p className="font-bold">{message}</p>
      </div>,
      { onConfirm: callback }
    );
  };
  const fetchOMData = async () => {
    ArchivoService.getAll({ creditoNumero: cacheCredit.numero })
    .then((response) => {
      response.estudiante.archivos.forEach(archivo => {
        if(archivo.tipoId === 'ORDEN_MATRICULA') {
          setOrdenMatricula(JSON.parse(archivo.rutaEnBlanco));
        }
      });
    })
    .catch((error) => {
      console.error(error);
    });
  };  

  useEffect(() => {
    const fetchOM = async () => await fetchOMData();
    fetchOM();
  }, []);
  return ordenMatricula ? (
    <>
      <section>
        <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
          Condiciones de Línea Andina
        </h2>
        <h4 className="font-bold text-center">
        Hemos terminado el estudio de tu solicitud y 
        de acuerdo a las características de tu aplicación, 
        estas son las condiciones de aprobación para tu orden de matrícula: 
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className=" grid text-right grid-cols-6">
              <p className="font-bold text-center text-red-500 col-span-6 m-1">
                Información de tu orden de matrícula:
              </p>
              <div className=" m-1 col-span-3">
                <b>
                  <span className="dark:text-custom-green col-span-2">N° Orden:</span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                {ordenMatricula?.nOrdenMatricula}
              </div>
              <div className="m-1 col-span-3">
                <b>
                  <span className="dark:text-custom-green col-span-3">
                    Fecha prevista de pago:
                  </span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                {ordenMatricula?.fecha}
              </div>
              <div className=" m-1 col-span-3">
                <b>
                  <span className="dark:text-custom-green col-span-2">
                    Matrícula:
                  </span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                {formatCurrency(parseInt(ordenMatricula?.matricula))}
              </div>

              <div className=" m-1 col-span-3">
                <b>
                  <span className="dark:text-custom-green col-span-2">Seguro:</span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                {formatCurrency(parseInt(ordenMatricula?.seguro))}
              </div>

              <div className=" m-1 col-span-3">
                <b>
                  <span className="text-[#3F2AAD] dark:text-custom-green col-span-2 ">
                    Valor a pagar:
                  </span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                {formatCurrency(parseInt(ordenMatricula?.valorPago))}
              </div>
            </div>
            </div>
            <h4 className="my-8 pb-6 text-center text-xl font-bold text-custom-green">
                Condiciones de aprobación
            </h4> 
          <div className="my-4 grid grid-cols-1 items-center text-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">                       
            <p className="col-start-1 font-bold">
              Valor que te financiaremos:
            </p>
            <p>
              {formatCurrency(parseInt(ordenMatricula?.valorPago))}
            </p>
            <p className='text-left'>
              *Valor que pagarás si no apruebas el requisito académico para condonación. 
              (No incluye tu aporte del 3% al Fondo de Promoción y Reserva + seguro).
            </p>
            <p className="col-start-1 font-bold">
              <span className="text-[#3F2AAD] dark:text-custom-green">
                Valor condonable:
              </span>
            </p>
            <p>
              {formatCurrency(parseInt(ordenMatricula?.matricula*(porcentajeCondonacion/100)))}
            </p>
            <p className='text-left'>
              *El beneficio contempla condonar este valor de la matrícula, si cumples requisitos académicos.
            </p>
            <p className="col-start-1 font-bold">
              <span className="text-custom-red">
                Lo que empezarás a pagar:
              </span>
            </p>
            <p>
              {formatCurrency(parseInt((ordenMatricula?.valorPago)-(ordenMatricula?.matricula*(porcentajeCondonacion/100))))}
            </p>
            <p className='text-left'>
              *Valor que pagarás siempre y cuando no hayas perdido tu beneficio de condonación. 
              (No incluye tu aporte del 3% al Fondo de Promoción y Reserva + seguro).
            </p>          
          </div>

          <p className='my-2 text-center'>
              <b>
              ¡Llegó el momento de calcular el tiempo <br />y cuota de tu
              financiación!
              </b>
            </p>
            <div className="flex justify-center">
              <Button
                type="submit"
                className='my-4'
                loading={isSubmitting}
                id="btnCalculate"
              >
                Calcular
              </Button>
            </div>
        </form>

        <div className="mt-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-4 lg:gap-6">
          <p className="col-span-1">
            <b>Selecciona el plazo</b> en la barra y{' '}
            <b>visualiza el valor aproximado</b> de tu cuota mensual:
          </p>
          <TermSlider
            {...InputProps('term')}
            classNameBar="lg:col-span-2 col-span-1"
          />
        </div>

        <div className="my-4 text-center">
          <p className='my-5'>
            Recuerda que tu preaprobación <b>vence 24 horas</b> posterior a la respuesta de estudio. <br />
            Luego de este tiempo el sistema automáticamente cancelará y marcará como desistida la solicitud.
          </p>
          <Button
            className="m-4"
            onClick={() =>
              showDialog(
                '¿Confirmas el DESISTIMIENTO de tu solicitud?',
                sendAndinaVariantCancel
              )
            }
            disabled={!btnAcceptAndina}
          >
            Desistir Solicitud
          </Button>
          <Button
            onClick={() =>
              showDialog('¿Confirmas tus CONDICIONES de Línea Andina?', sendAndinaVariant)
            }
            disabled={!btnAcceptAndina}
          >
            Aceptar Línea Andina
          </Button>
        </div>
      </section>
    </>
  ) : (
  <Loading />
  );
};

export default AndinaVariant;
