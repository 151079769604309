import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/DynamicForm/Button';
import CoheteImage from '../../assets/images/financing-request/cohete.png';
import { RETURN } from '../../constants/actions/buttons.constants';

const FormConfirm = () => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="relative mb-5">
        <div className="px-12 pb-4 dark:opacity-50">
          <img src={CoheteImage} alt="cohete" />
        </div>
        <div className="absolute bottom-0 w-full text-center">
          <p className="text-sm font-bold ">
            Este paso se ha enviado: ¡EXITOSAMENTE!
          </p>
          <p className="text-3xl font-bold text-pink-700">Regresa y termina</p>
          <p className="text-sm font-bold">
            tu solicitud de financiación
          </p>
        </div>
      </div>
      <Button>
        <Link to={'/solicitar-financiacion'}>{'<'} {RETURN}</Link>
      </Button>
    </div>
  );
};

export default FormConfirm;
