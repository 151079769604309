import { createAsyncThunk } from '@reduxjs/toolkit';
import EtapaService from '../../services/stage/etapa-service';

export const fetchStages = createAsyncThunk(
  'stages/fetchEtapas',
  async ({ user }, thunkAPI) => {
    try{
      const response = await EtapaService.get({ usuarioId: user.sub });
      return response;
    }catch(error) {
        return thunkAPI.rejectWithValue(error.message);
    }
    
  }
);

export const updateEtapas = createAsyncThunk(
  'stages/updateEtapas',
  async ({ creditoNumero, etapas }, thunkAPI) => {
    try{
        const response = await EtapaService.updateEtapas({ creditoNumero, etapas });
        return response;
     }catch(error) {
        return thunkAPI.rejectWithValue(error.message);
    }
  }
);