import React from 'react';
import { Link } from 'react-router-dom';
import { STEP_STATE } from '../../constants/step/step';
import { ALERTDIALOG } from '../../constants/dialog/alertDialog';
import { ReactComponent as CompletedIcon } from '../../assets/images/home/completed.svg';
import { ReactComponent as FailedIcon } from '../../assets/images/home/failed.svg';
import { ReactComponent as PendingIcon } from '../../assets/images/home/pending.svg';
import { useState, useEffect } from 'react';
import { Cache } from 'aws-amplify';
import { DIALOG } from '../../constants/dialog/dialog';
import FormService from '../../services/form/form-service';
import { useDialog } from '../../context/dialog-context';

import EtapaService from '../../services/stage/etapa-service';
import { useAuth } from '../../context/auth-context';
import ZapsignService from '../../services/process/zapsign-service';
import OpenCoopCrmService from '../../services/verification/open-coop-service';

function ServiceLink({ link, FormIcon, className, state }) {
  const { user } = useAuth();
  if (!state) return '';
  const { numero, descripcion, estado } = state;
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  let [descripcionAlert, setDescripcionAlert] = useState('');
  const [searchProLine, setSearchProLine] = useState(true);
  const [reviewOM, setReviewOM] = useState('');
  const [enMora, setEnMora] = useState(false);
  let allNew = true;

  useEffect(() => {
    if (cacheCredit?.numero !== null && cacheCredit?.numero !== undefined) {
      postZapsign();
    }
  }, []);

  const dialog = useDialog();
  const disabled =
    estado === STEP_STATE.DISABLED ||
    estado === STEP_STATE.COMPLETED ||
    enMora ||
    (cacheCredit?.estado?.id === STEP_STATE.FAILED && estado !== 1) ||
    (cacheCredit?.estado?.id === 6 && estado !== 1) ||
    (cacheCredit?.estado?.id === 10 && estado !== 1) ||
    (cacheCredit?.estado?.id === 2 && estado !== 1);

  const postZapsign = async () => {
    const body = {
      creditId: cacheCredit?.numero,
      rol: 'consultRegister',
    };
    await ZapsignService.postZapsign(body) // Pasar la señal del controlador de aborto a la llamada de ZapsignService.postZapsign
      .then((res) => {
        if (state.numero !== 1 && res.listZapsignUser.length > 0) {
          const re = res.listZapsignUser.filter(
            (r) => r.docNumber.toString() === cacheCredit?.estudiante.documento
          );
        console.log(cacheCredit?.numero);
          console.log(cacheCredit?.numero);
  
          if (re[0].status !== 'signed') {
            allNew = false;
          }
        }
        if (allNew) {
          if (res.ordenMatriculaStatus.length > 0) {
            if (res.ordenMatriculaStatus[0].toState === 1) {
              setReviewOM(res.ordenMatriculaStatus[0].toState);
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkFinancialBlockade = async () => {
    const body = {
      nro_identificacion: user['custom:document'],
      rol: 'consultaOpenCreditosPantalla',
    };
    const resul = await OpenCoopCrmService.postOpenCoop(body);
    if (resul?.listCustomer?.length > 0 && !enMora) {
      
      resul?.listCustomer[0].forEach((item) => {
        if(item.dias_mora >= 5) {
          setEnMora(true);
          return;
        }
      });
    }
  };

  useEffect(() => {
    const checkData = async () => {
    await checkFinancialBlockade();
    };
    checkData();
  }, []);

  const getColor = (estado) => {
    console.log(reviewOM);
    switch (estado) {
      case STEP_STATE.ENABLED:
        if (state.numero === 2) {
          descripcionAlert = ALERTDIALOG.P2PENDINIDENTITY;
          return 'text-yellow-400';
        }
        else if(state.numero === 3) {
          descripcionAlert = ALERTDIALOG.P3PENDINSIGNATURE;
          return 'text-yellow-400';
        }
        return 'text-custom-light dark:text-input-dark';
      case STEP_STATE.COMPLETED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERCOMPLETE;
        return 'text-custom-green border-custom-green';
      case STEP_STATE.PENDING:
        if (
          (state.message &&
            state.message === 'Orden de matrícula bajo revisión') ||
          reviewOM === 1
        ) {
          descripcionAlert = ALERTDIALOG.P1PENDINGORDERREVIEW;
          return 'text-custom-purple dark:text-custom-green';
        }
        else if(state.numero === 2) {
          descripcionAlert = ALERTDIALOG.P2PENDINIDENTITY;
          return 'text-yellow-400';
        }
        else if(state.numero === 3) {
          descripcionAlert = ALERTDIALOG.P3PENDINSIGNATURE;
          return 'text-yellow-400';
        }
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERMatricula;
        return 'text-error';
      // if (state.message && state.message === "Pendiente subir orden de matrícula" && descripcionAlert !== ALERTDIALOG.P1PENDINGORDERLP) {
      //     descripcionAlert = ALERTDIALOG.P1PENDINGORDERMatricula;
      //     return 'text-error';
      // }
      // if (descripcionAlert !== ALERTDIALOG.P1PENDINGORDERLP) {
      //     descripcionAlert = ALERTDIALOG.P1PENDINGORDER;
      //     return 'text-custom-purple dark:text-yellow-400';
      // }

      case STEP_STATE.FAILED:
        if (state.numero === 2) {
          descripcionAlert = ALERTDIALOG.P2FAILEDIDENTITYCHECK;
        }
        else if(state.numero === 3) {
          descripcionAlert = ALERTDIALOG.P3FAILEDSIGNATURE;
        }
        else {
          descripcionAlert = ALERTDIALOG.P1PENDINGORDERNOVELTY;
        }
        return 'text-error';
      case STEP_STATE.DISABLED:
        return 'text-input-light dark:text-input-dark opacity-50';
      case STEP_STATE.ZAPSIGN:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDER;
        return 'text-error';
      default:
        return '';
    }
  };
  const fetchStep = () =>
    EtapaService.get({ usuarioId: user.sub })
      .then((response) => {
        if (typeof response.loanId === 'undefined') {
          setSearchProLine(false);
          setDescripcionAlert('');
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });
  useEffect(() => {
    if (user) fetchStep();
  }, [user]);

  if (!cacheCredit) {
    let linea =
      (cacheCredit?.lineaId === '2' && cacheCredit?.universidadId === '2') ||
      (cacheCredit?.lineaId === '5' && cacheCredit?.universidadId === '3');
    if (
      cacheCredit?.linea === 'Línea Pro' ||
      cacheCredit?.linea === 'Línea Heroica'
    ) {
      linea = true;
    }
    if (linea && searchProLine) {
      FormService.getAnswers(cacheCredit?.numero)
        .then((response) => {
          if (!(response.data?.length > 0)) {
            if (
              state.numero === 1 &&
              state.estado === STEP_STATE.ZAPSIGN &&
              state.message?.length > 0
            ) {
              setDescripcionAlert(ALERTDIALOG.P1PENDINGORDER);
            }
            if (
              state.numero === 1 &&
              state.estado === STEP_STATE.PENDING &&
              state.message?.length > 0
            ) {
              setDescripcionAlert(ALERTDIALOG.P1PENDINGORDERLP);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
    }
  }
  const renderIcon = (estado) => {
    switch (estado) {
      case STEP_STATE.COMPLETED:
        return (
          <CompletedIcon className="h-7 w-auto flex-shrink-0 fill-current text-custom-purple dark:text-custom-green" />
        );
      case STEP_STATE.PENDING:
        return (
          <PendingIcon className="h-7 w-auto flex-shrink-0 fill-current" />
        );
      case STEP_STATE.ZAPSIGN:
        return (
          <PendingIcon className="h-7 w-auto flex-shrink-0 fill-current" />
        );
      case STEP_STATE.FAILED:
        return <FailedIcon className="h-7 w-auto flex-shrink-0 fill-current" />;
      case STEP_STATE.ENABLED:
      case STEP_STATE.DISABLED:
        return <PendingIcon className="h-7 w-auto flex-shrink-0 fill-current" />;
      default:
        return undefined;
    }
  };

  return (
    <Link
      className={disabled ? 'cursor-default' : undefined}
      to={link}
      onClick={disabled ? (e) => e.preventDefault() : undefined}
    >
      <div
        className={`relative h-full rounded-3xl py-4 px-20 text-center shadow-light-active transition duration-500 dark:shadow-dark-active ${enMora ? getColor(STEP_STATE.DISABLED) : getColor(
          estado
        )} ${className}`}
      >
        <div className="flex h-full flex-col items-center justify-center space-y-4">
          <FormIcon className="h-12 fill-current" />
          <p className="font-bold">
            {numero}. {descripcion}
          </p>
          <p className="font-bold">
            {!disabled ? (numero ? descripcionAlert : '') : undefined}
          </p>
        </div>
        <span className="absolute top-2 right-2">{renderIcon(estado)}</span>
      </div>
    </Link>
  );
}

export default ServiceLink;
