import { Auth } from 'aws-amplify';

const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error('Error getting current user token:', error);
    return null;
  }
};

//pre-prod testing
const amplifyConfig = {
  API: {
    endpoints: [
      {
        name: 'estrella',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          console.log(process.env.REACT_APP_API_ENDPOINT);
          const token = await getCurrentUserToken();
          return {
            'X-Cognito-Token': token,
            'Authorization': token,
          };
        }
      }
    ]
  },
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
};

export default amplifyConfig;