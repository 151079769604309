import { createSlice } from '@reduxjs/toolkit';
import { fetchCredit } from './creditThunks';
import { IS_FAILED, IS_LOADING, IS_SUCCEEDED } from '../../constants/redux/states';

const creditSlice = createSlice({
  name: 'credit',
  initialState: {
    data: {},
    status: IS_LOADING,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCredit.pending, (state) => {
        state.status = IS_LOADING;
      })
      .addCase(fetchCredit.fulfilled, (state, action) => {
        state.status = IS_SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchCredit.rejected, (state, action) => {
        state.status = IS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default creditSlice.reducer;