import { createAsyncThunk } from '@reduxjs/toolkit';
import FormService from '../../services/form/form-service';

// Thunk para obtener el formulario
export const getFormThunk = createAsyncThunk(
  'form/getForm',
  async (line, { rejectWithValue }) => {
    try {
      const response = await FormService.getForm(line);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk para obtener las respuestas del formulario
export const getAnswersThunk = createAsyncThunk(
  'form/getAnswers',
  async (loanId, { rejectWithValue }) => {
    try {
      const response = await FormService.getAnswers(loanId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk para enviar las respuestas del formulario
export const postAnswersThunk = createAsyncThunk(
  'form/postAnswers',
  async (body, { rejectWithValue }) => {
    try {
      const response = await FormService.postAnswers(body);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);