import { API } from 'aws-amplify';

const postZapsign = (body) =>
  // esto debe apuntar a create-doc-autorization sin el body zapsign
  API.post('estrella', '/p', {
    body: {
      data: body,
      model: 'Autorizacion',
    },
  });

const postContratoSeguroZapsign = (body) =>
  API.post('estrella', '/p', {
    body: { data: body, model: 'Contracto de Credito' },
  });
const postSeguroVidaZapsign = (body) =>
  API.post('estrella', '/p', {
    body: { data: body, model: 'Pagare Seguro Vida' },
  });

const postDeceval = (body) =>
  API.post('estrella', '/p', {
    body: { data: body, model: 'Deceval' },
  });
const ZapsignService = {
  postZapsign,
  postContratoSeguroZapsign,
  postSeguroVidaZapsign,
  postDeceval,
};

export default ZapsignService;
