import { configureStore } from '@reduxjs/toolkit';
import stagesReducer from '../features/stages/stagesSlice';
import creditReducer from '../features/credit/creditSlice';
import fileReducer from '../features/files/fileSlice';
import signatureReducer from '../features/signature/signatureSlice';
import loanSimulationReducer from '../features/loanSimulation/loanSimulationSlice';
import hubspotReducer from '../features/hubspot/hubspotSlice';
import formReducer from '../features/form/formSlice';
import paymentReducer from '../features/payment/paymentSlice';

const store = configureStore({
  reducer: {
    stages: stagesReducer,
    credit: creditReducer,
    files: fileReducer,
    signature: signatureReducer,
    loanSimulation: loanSimulationReducer,
    hubspot: hubspotReducer,
    form: formReducer,
    payment: paymentReducer

  },
});

export default store;