import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import Spinner from '../shared/Spinner';

const FormattedInput = ({
  name,
  placeholder,
  type = 'text',
  value,
  className,
  control,
  validation,
  errors,
  disabled = false,
  loading = false,
  preventCopy = false,
  preventPaste = false,
  allowDecimals = false,
  ...otherProps
}) => {
  const [active, setActive] = useState(loading || value?.toString().length > 0);
  useEffect(
    () => setActive(loading || value?.toString().length > 0),
    [loading, value]
  );
  const props = otherProps;
  let Input;

  if (preventPaste)
    props.onPaste = (e) => {
      e.preventDefault();
      return false;
    };
  if (preventCopy)
    props.onCopy = (e) => {
      e.preventDefault();
      return false;
    };

  if (type === 'currency' || type === 'document') {
    props.onKeyDown = (e) => {
      // Bloquear punto y coma
      if (e.key === '.' || e.key === ',') {
        e.preventDefault();
        return false;
      }
    };
  }

  switch (type) {
    case 'cell-phone':
      props.type = 'tel';
      props.mask = '_';
      props.format = '(###) ###-####';
      Input = PatternFormat;
      break;
    case 'home-phone':
      props.type = 'tel';
      props.mask = '_';
      props.format = '###-####';
      Input = PatternFormat;
      break;
    case 'phone':
      props.type = 'tel';
      props.mask = '_';
      props.format =
        value?.toString().length < 8 ? '###-#####' : '(###) ###-####';
      Input = PatternFormat;
      break;
    case 'currency':
      props.prefix = '$';
      props.thousandSeparator = '.';
      props.decimalSeparator = ',';
      props.allowNegative = false;
      props.allowLeadingZeros = false;
      if (allowDecimals) {
        props.decimalScale = 2;
        props.valueIsNumericString = true;
      }
      Input = NumericFormat;
      break;
    case 'document':
      props.thousandSeparator = '.';
      props.decimalSeparator = ',';
      props.allowNegative = false;
      props.allowLeadingZeros = false;
      Input = NumericFormat;
      break;
    default:
      props.type = type;
      Input = NumericFormat;
      break;
  }

  return (
    <div
      className={`relative ${
        errors ? 'text-error' : 'text-input-light dark:text-input-dark'
      } ${className}`}
    >
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Input
            getInputRef={ref}
            name={name}
            title={placeholder}
            valueIsNumericString
            value={value}
            onValueChange={(values) => onChange(values.value)}
            onBlur={(event) => {
              onBlur(event);
              setActive(loading || event.target.value.length > 0);
            }}
            onFocus={() => setActive(true)}
            disabled={loading || disabled}
            {...props}
            className={`form-Input block min-h-full w-full flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-light bg-opacity-100 py-2 pl-4 pr-10 shadow-light transition duration-500 dark:bg-dark dark:shadow-dark ${
              disabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
          />
        )}
      />

      {loading ? (
        <div className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-6" />
        </div>
      ) : undefined}

      <div
        className={`pointer-events-none absolute flex h-full w-full origin-left items-center transition-all duration-500 ${
          active
            ? `-inset-y-2 -translate-y-1/2 text-2xs ${
                errors ? '' : 'text-custom-green'
              }`
            : `inset-y-0 px-4 ${disabled ? 'opacity-50' : ''}`
        }`}
      >
        <label
          htmlFor={name}
          className="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default FormattedInput;
